import React, { useState, useEffect, useRef } from "react";
import Index from "../../../../../component/Index";
import { useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";

import { useParams } from "react-router-dom";
import { getPurchasePrintDataApiHandler } from "../../../../../services/AdminIndex";
import moment from "moment";
import QRImage from "../../salesEntry/invoice/qr-download.png";
import { ToWords } from "to-words";

export default function Preview() {
  const { id } = useParams();
  const editId = id;
  const toWords = new ToWords();

  const dispatch = useDispatch();
  const [productData, setProductData] = useState([]);
  const [poData, setPoData] = useState([]);
  const [companyAddress, setCompanyAddress] = useState();
  const [supplierAddress, setSupplierAddress] = useState();
  const [printData, setPrintData] = useState({});
  const [taxData, setTaxData] = useState([]);

  const { token } = Index.useSelector((state) => {
    return state.ERPAdminReducer;
  });

  const getPrintData = () => {
    dispatch({ type: "LOADER_START" });
    getPurchasePrintDataApiHandler(token, editId).then((data) => {
      dispatch({ type: "LOADER_START" });
      if (data.status === 201 || data.status === 200) {
        setPrintData(data?.data);
        const companyAddress = parseData(data?.data?.companyAddress);
        setCompanyAddress(companyAddress?.[0]);

        const productData = parseData(data?.data?.productData);
        setProductData(productData);

        const tax = parseData(data?.data?.taxData);
        setTaxData(tax);

        const poData = parseData(data?.data?.purchaseOrderData);
        setPoData(poData);

        const supplierAddress = parseData(data?.data?.supplierAddress);
        setSupplierAddress(supplierAddress?.[0]);

        dispatch({ type: "LOADER_END" });
      } else {
        dispatch({ type: "ADMIN_ERROR_HANDLER", payload: data });
      }
    });
  };

  // Call edit API
  useEffect(() => {
    getPrintData();
  }, []);

  const parseData = (data) => {
    try {
      // if plain js
      if (data) {
        let result = JSON.parse(data);
        return result;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  };

  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    repeatTableHead: true,
  });

  // if (loading) {
  //   <LoadingSpinner></LoadingSpinner>;
  // } else {
  return (
    <>
      <div className="grn-print-container" ref={printRef}>
        <div className="grn-order-print" style={{ margin: "10px" }}>
          <table
            style={{
              backgroundColor: "#fff",
              margin: "auto",
              border: "1px solid #9f9e9e",
              width: "100%",
            }}
            border={0}
            cellSpacing={0}
            cellPadding={0}
            role="presentation"
          >
            <tbody>
              <tr>
                <td
                  colSpan={5}
                  style={{ border: "1px solid #9f9e9e", padding: "10px" }}
                >
                  <table
                    border={0}
                    cellSpacing={0}
                    cellPadding={0}
                    style={{ border: 0, width: "100%" }}
                  >
                    <tr>
                      <td
                        style={{
                          width: "20%",
                          paddingLeft: "20px",
                        }}
                      >
                        {printData?.companyLogo ? (
                          <img
                            src={
                              process.env.REACT_APP_IMAGE_URL +
                              printData?.companyLogo
                            }
                            // height="60px"
                            // width="100px"
                            style={{
                              objectFit: "contain",
                              height: "100%",
                              width: "100%",
                              maxWidth: "100px",
                              maxHeight: "60px",
                            }}
                          />
                        ) : (
                          <></>
                        )}
                      </td>
                      <td>
                        <tr>
                          <td
                            style={{
                              fontSize: 15,
                              lineHeight: 1.2,
                              color: "#666666",
                              verticalAlign: "top",
                              borderBottom: 0,
                              textAlign: "center",
                              width: "40%",
                            }}
                          >
                            <h2
                              style={{
                                color: "#222222",
                                margin: "0px 0px 5px 0px",
                                fontSize: "25px",
                              }}
                            >
                              {companyAddress?.Company_name}
                            </h2>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              borderBottom: 0,
                              fontSize: 11,
                              lineHeight: 1.2,
                              padding: "1px 10px 5px",
                              color: "#666666",
                              verticalAlign: "middle",
                              paddingTop: 0,
                              paddingBottom: 0,
                              textAlign: "center",
                            }}
                            className="address"
                          >
                            <p
                              style={{
                                textAlign: "center",
                                lineHeight: 1.2,
                                margin: 0,
                                fontSize: 11,
                                color: "#666666",
                              }}
                            >
                              {`${companyAddress?.o_add1 || ""}, ${
                                companyAddress?.o_add2 || ""
                              }, `}
                            </p>
                            <p
                              style={{
                                textAlign: "center",
                                lineHeight: 1.2,
                                margin: 0,
                                fontSize: 11,
                                color: "#666666",
                              }}
                            >
                              {`${companyAddress?.o_add3 || ""}, ${
                                companyAddress?.cityName || ""
                              } - ${companyAddress?.o_pin || ""}, ${
                                companyAddress?.Statename || ""
                              } - ${companyAddress?.sCode || ""}`}
                            </p>
                            <p
                              style={{
                                textAlign: "center",
                                lineHeight: 1.2,
                                margin: 0,
                                fontSize: 11,
                                color: "#666666",
                              }}
                            >
                              Contact No : {printData.companyMobileNo}, Email Id
                              : {printData.companyEmail}
                            </p>
                            <p
                              style={{
                                textAlign: "center",
                                lineHeight: 2,
                                margin: 0,
                                fontWeight: "700",
                                fontSize: 11,
                                color: "#666666",
                                letterSpacing: "1.2px",
                              }}
                            >
                              GSTIN NO : {printData.companyGSTNo}, PAN NO :
                              {printData.companyPanNo}
                            </p>
                            <p />
                          </td>
                        </tr>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <img
                          src={QRImage}
                          alt="qr"
                          style={{ height: "70px", width: "70px" }}
                        />
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td style={{ padding: "0px" }}>
                  <table
                    style={{
                      width: "100%",
                      backgroundColor: "#efefef",
                      borderBottom: "1px solid #9f9e9e",
                      borderCollapse: "collapse",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td style={{ width: "100%", padding: "3px" }}>
                          <h3
                            style={{
                              textTransform: "uppercase",
                              fontSize: "11px",
                              lineHeight: "16px",
                              color: "#000",
                              margin: "0px",
                              fontFamily: '"popins-regular", sans-serif',
                              textAlign: "center",
                            }}
                          >
                            DEBIT NOTE
                          </h3>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td style={{ padding: "0px" }}>
                  <table
                    style={{
                      borderBottom: "1px solid #9f9e9e",
                      borderCollapse: "collapse",
                      width: "100%",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          className="padding-none border-none"
                          width="50%"
                          style={{
                            fontSize: 10,
                            padding: 0,
                            color: "#666666",
                            verticalAlign: "top",
                            border: 0,
                          }}
                        >
                          <table
                            border={0}
                            cellSpacing={0}
                            cellPadding={0}
                            style={{
                              width: "100%",
                            }}
                          >
                            <tbody>
                              <tr>
                                <td
                                  className="label"
                                  style={{
                                    borderRight: "1px solid #9f9e9e",
                                    fontSize: 10,
                                    padding: "1px 6px",
                                    color: "#666666",
                                    verticalAlign: "top",
                                  }}
                                >
                                  <p style={{ margin: "0 0 0px" }}>
                                    <b style={{ color: "#222222" }}>
                                      {`To : ${printData?.supplier || ""}`}
                                    </b>
                                  </p>
                                  <p
                                    style={{
                                      margin: "0 0 0px",
                                      minHeight: "18px",
                                    }}
                                  >
                                    {supplierAddress?.address1}
                                  </p>
                                  <p
                                    style={{
                                      margin: "0 0 0px",
                                      minHeight: "18px",
                                    }}
                                  >
                                    {supplierAddress?.address2}
                                  </p>
                                  <p
                                    style={{
                                      margin: "0 0 0px",
                                      minHeight: "18px",
                                    }}
                                  >
                                    {supplierAddress?.address3}
                                  </p>
                                  <p
                                    style={{
                                      margin: "0 0 0px",
                                      minHeight: "18px",
                                    }}
                                  >
                                    {`${supplierAddress?.cityName || ""} - ${
                                      supplierAddress?.pin || ""
                                    }, ${supplierAddress?.Statename || ""} - ${
                                      supplierAddress?.sCode || ""
                                    }`}
                                  </p>
                                  <p
                                    style={{
                                      margin: "0 0 0px",
                                      minHeight: "18px",
                                    }}
                                  >
                                    GSTIN NO : {printData?.supplierGSTNo || ""}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PAN
                                    NO : {printData?.supplierPanNo || ""}
                                  </p>
                                  <p
                                    style={{
                                      margin: "0 0 0px",
                                      minHeight: "18px",
                                    }}
                                  >
                                    Contact No :{" "}
                                    {`${printData?.supplierMobile || ""}/${
                                      printData?.supplierOfficeNumber || ""
                                    }`}
                                  </p>
                                  {printData?.supplierMSME &&
                                    printData?.supplierMSME != 0 && (
                                      <p
                                        style={{
                                          margin: "0 0 0px",
                                          minHeight: "18px",
                                        }}
                                      >
                                        MSME No :{" "}
                                        {printData?.supplierMSME || ""}
                                      </p>
                                    )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td
                          style={{
                            padding: "0px",
                            width: "50%",
                            verticalAlign: "top",
                          }}
                        >
                          <table style={{ width: "100%" }}>
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    width: "100%",
                                    borderBottom: "1px solid #9f9e9e",
                                    padding: "0px 0px 0px 0px",
                                  }}
                                >
                                  <table>
                                    <tbody>
                                      <tr>
                                        <td
                                          className="label"
                                          style={{
                                            fontSize: 10,
                                            lineHeight: 1.5,
                                            padding: "3.5px 6px",
                                            color: "#666666",
                                            verticalAlign: "top",
                                            width: "50%",
                                          }}
                                        >
                                          <b style={{ color: "#222222" }}>
                                            Debit No:
                                          </b>
                                          <span style={{ paddingLeft: "18px" }}>
                                            {printData?.noteNo}
                                          </span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          className="label"
                                          style={{
                                            fontSize: 10,
                                            lineHeight: 1.5,
                                            padding: "3.5px 6px",
                                            color: "#666666",
                                            verticalAlign: "top",
                                            width: "50%",
                                          }}
                                        >
                                          <b style={{ color: "#222222" }}>
                                            Date:
                                          </b>
                                          <span style={{ paddingLeft: "38px" }}>
                                            {moment(printData?.noteDate).format(
                                              "DD/MM/YYYY"
                                            )}
                                          </span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style={{ padding: "0px" }}></td>
                                      </tr>
                                      <tr>
                                        <td style={{ padding: "0px" }}></td>
                                      </tr>
                                      <tr>
                                        <td style={{ padding: "0px" }}></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <table style={{ width: "100%" }}>
                                    <tbody>
                                      <tr>
                                        <td
                                          className="label"
                                          style={{
                                            fontSize: 10,
                                            lineHeight: 1.5,
                                            padding: "3.5px 6px",
                                            color: "#666666",
                                            verticalAlign: "top",
                                            width: "50%",
                                          }}
                                        >
                                          <b style={{ color: "#222222" }}>
                                            NOTE:
                                          </b>
                                          <span style={{ paddingLeft: "32px" }}>
                                            GOOD RETURN AGAINST
                                            {/* {poData?.[0]?.date
                                              ? Array.prototype.map
                                                  .call(
                                                    poData,
                                                    function (item) {
                                                      return item?.purchaseNo;
                                                    }
                                                  )
                                                  .join(", ")
                                              : "N/A"} */}
                                          </span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          className="label"
                                          style={{
                                            fontSize: 10,
                                            lineHeight: 1.5,
                                            padding: "3.5px 6px",
                                            color: "#666666",
                                            verticalAlign: "top",
                                            width: "50%",
                                          }}
                                        >
                                          <b style={{ color: "#222222" }}>
                                            Bill No:
                                          </b>
                                          <span style={{ paddingLeft: "29px" }}>
                                            {printData?.againstBillNo}
                                          </span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          className="label"
                                          style={{
                                            fontSize: 10,
                                            lineHeight: 1.5,
                                            padding: "3.5px 6px",
                                            color: "#666666",
                                            verticalAlign: "top",
                                            width: "50%",
                                          }}
                                        >
                                          <b style={{ color: "#222222" }}>
                                            Bill Date:
                                          </b>
                                          <span style={{ paddingLeft: "20px" }}>
                                            {printData?.againstBillDate
                                              ? moment(
                                                  printData?.againstBillDate
                                                ).format("DD-MM-YYYY")
                                              : "-"}
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td style={{ padding: "0px" }}>
                  <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <thead>
                      <tr>
                        <th
                          style={{
                            fontSize: "11px",
                            lineHeight: "16px",
                            color: "#222222",
                            margin: "0px",
                            textAlign: "left",
                            borderRight: "1px solid #9f9e9e",
                            borderBottom: "1px solid #9f9e9e",
                            backgroundColor: "#efefef",
                            padding: "3px 6px",
                            width: "3%",
                          }}
                        >
                          Sr.
                        </th>
                        <th
                          style={{
                            fontSize: "11px",
                            lineHeight: "16px",
                            color: "#222222",
                            margin: "0px",
                            textAlign: "left",
                            borderRight: "1px solid #9f9e9e",
                            borderBottom: "1px solid #9f9e9e",
                            backgroundColor: "#efefef",
                            padding: "3px 6px",
                            width: "33%",
                          }}
                        >
                          Product
                        </th>
                        <th
                          style={{
                            fontSize: "11px",
                            lineHeight: "16px",
                            color: "#222222",
                            margin: "0px",
                            textAlign: "left",
                            borderRight: "1px solid #9f9e9e",
                            borderBottom: "1px solid #9f9e9e",
                            backgroundColor: "#efefef",
                            padding: "3px 6px",
                            width: "8%",
                          }}
                        >
                          HSN
                        </th>
                        <th
                          style={{
                            fontSize: "11px",
                            lineHeight: "16px",
                            color: "#222222",
                            margin: "0px",
                            textAlign: "left",
                            borderRight: "1px solid #9f9e9e",
                            borderBottom: "1px solid #9f9e9e",
                            backgroundColor: "#efefef",
                            padding: "3px 6px",
                            width: "8%",
                          }}
                        >
                          Colour
                        </th>
                        <th
                          style={{
                            fontSize: "11px",
                            lineHeight: "16px",
                            color: "#222222",
                            margin: "0px",
                            textAlign: "left",
                            borderRight: "1px solid #9f9e9e",
                            borderBottom: "1px solid #9f9e9e",
                            backgroundColor: "#efefef",
                            padding: "3px 6px",
                            width: "8%",
                          }}
                        >
                          Size
                        </th>
                        <th
                          style={{
                            fontSize: "11px",
                            lineHeight: "16px",
                            color: "#222222",
                            margin: "0px",
                            textAlign: "left",
                            borderRight: "1px solid #9f9e9e",
                            borderBottom: "1px solid #9f9e9e",
                            backgroundColor: "#efefef",
                            padding: "3px 6px",
                            width: "8%",
                          }}
                        >
                          Unit
                        </th>
                        <th
                          style={{
                            fontSize: "11px",
                            lineHeight: "16px",
                            color: "#222222",
                            margin: "0px",
                            textAlign: "left",
                            borderRight: "1px solid #9f9e9e",
                            borderBottom: "1px solid #9f9e9e",
                            backgroundColor: "#efefef",
                            padding: "3px 6px",
                            width: "8%",
                          }}
                        >
                          Qty
                        </th>
                        <th
                          style={{
                            fontSize: "11px",
                            lineHeight: "16px",
                            color: "#222222",
                            margin: "0px",
                            textAlign: "left",
                            borderRight: "1px solid #9f9e9e",
                            borderBottom: "1px solid #9f9e9e",
                            backgroundColor: "#efefef",
                            padding: "3px 6px",
                            width: "8%",
                          }}
                        >
                          Rate
                        </th>
                        <th
                          style={{
                            fontSize: "11px",
                            lineHeight: "16px",
                            color: "#222222",
                            margin: "0px",
                            textAlign: "right",
                            // borderRight: "1px solid #9f9e9e",
                            borderBottom: "1px solid #9f9e9e",
                            backgroundColor: "#efefef",
                            padding: "3px 6px",
                            width: "8%",
                          }}
                        >
                          Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {productData.map((row, index) => (
                        <tr style={{ borderBottom: "1px solid #9f9e9e" }}>
                          <td
                            style={{
                              fontSize: 9,
                              lineHeight: "16px",
                              fontWeight: 400,
                              color: "#222222",
                              margin: "0px",
                              textAlign: "left",
                              borderRight: "1px solid #9f9e9e",
                              padding: "5px",
                            }}
                          >
                            {index + 1}
                          </td>
                          <td
                            style={{
                              fontSize: 9,
                              lineHeight: "16px",
                              fontWeight: 400,
                              color: "#222222",
                              margin: "0px",
                              textAlign: "left",
                              borderRight: "1px solid #9f9e9e",
                              padding: "5px",
                            }}
                          >
                            {row?.ProductName || ""}
                          </td>
                          <td
                            style={{
                              fontSize: 9,
                              lineHeight: "16px",
                              fontWeight: 400,
                              color: "#222222",
                              margin: "0px",
                              textAlign: "left",
                              borderRight: "1px solid #9f9e9e",
                              padding: "5px",
                            }}
                          >
                            {row?.HSNCode || ""}
                          </td>
                          <td
                            style={{
                              fontSize: 9,
                              lineHeight: "16px",
                              fontWeight: 400,
                              color: "#222222",
                              margin: "0px",
                              textAlign: "left",
                              borderRight: "1px solid #9f9e9e",
                              padding: "5px",
                            }}
                          >
                            {row?.color_name || ""}
                          </td>
                          <td
                            style={{
                              fontSize: 9,
                              lineHeight: "16px",
                              fontWeight: 400,
                              color: "#222222",
                              margin: "0px",
                              textAlign: "left",
                              borderRight: "1px solid #9f9e9e",
                              padding: "5px",
                            }}
                          >
                            {row?.size_name || ""}
                          </td>
                          <td
                            style={{
                              fontSize: 9,
                              lineHeight: "16px",
                              fontWeight: 400,
                              color: "#222222",
                              margin: "0px",
                              textAlign: "left",
                              borderRight: "1px solid #9f9e9e",
                              padding: "5px",
                            }}
                          >
                            {row?.unitCode || ""}
                          </td>
                          <td
                            style={{
                              fontSize: 9,
                              lineHeight: "16px",
                              fontWeight: 400,
                              color: "#222222",
                              margin: "0px",
                              textAlign: "left",
                              borderRight: "1px solid #9f9e9e",
                              padding: "5px",
                            }}
                          >
                            {Number(row?.qty || 0).toFixed(2)}
                          </td>
                          <td
                            style={{
                              fontSize: 9,
                              lineHeight: "16px",
                              fontWeight: 400,
                              color: "#222222",
                              margin: "0px",
                              textAlign: "left",
                              borderRight: "1px solid #9f9e9e",
                              padding: "5px",
                            }}
                          >
                            {Number(row?.rate || 0).toFixed(2)}
                          </td>
                          <td
                            style={{
                              fontSize: 9,
                              lineHeight: "16px",
                              fontWeight: 400,
                              color: "#222222",
                              margin: "0px",
                              textAlign: "left",
                              // borderRight: "1px solid #9f9e9e",
                              padding: "5px",
                              textAlign: "right",
                            }}
                          >
                            {Number(row?.amount || 0).toFixed(2)}
                          </td>
                        </tr>
                      ))}
                      {/* {Array.apply(null, {length: 15-productData?.length}).map((row, index) => (
                         <tr>&nbsp;</tr>
                      ))} */}
                      <tr style={{ borderBottom: "1px solid #9f9e9e" }}>
                        <td
                          colSpan={4}
                          style={{
                            fontSize: "10px",
                            lineHeight: "16px",
                            fontWeight: 400,
                            color: "#000",
                            margin: "0px",
                            textAlign: "left",
                            borderRight: "1px solid #9f9e9e",
                            borderTop: "1px solid #9f9e9e",
                            padding: "5px",
                          }}
                        >
                          <b>MSME NO.:{printData?.MSMENo}</b>
                        </td>
                        {/* <td
                          style={{
                            fontSize: "10px",
                            lineHeight: "16px",
                            fontWeight: 400,
                            color: "#000",
                            margin: "0px",
                            textAlign: "left",
                            borderRight: "1px solid #9f9e9e",
                            borderTop: "1px solid #9f9e9e",
                            padding: "5px",
                          }}
                        ></td>
                        <td
                          style={{
                            fontSize: "10px",
                            lineHeight: "16px",
                            fontWeight: 400,
                            color: "#000",
                            margin: "0px",
                            textAlign: "left",
                            borderRight: "1px solid #9f9e9e",
                            borderTop: "1px solid #9f9e9e",
                            padding: "5px",
                          }}
                        ></td>
                        <td
                          style={{
                            fontSize: "10px",
                            lineHeight: "16px",
                            fontWeight: 400,
                            color: "#000",
                            margin: "0px",
                            textAlign: "left",
                            borderRight: "1px solid #9f9e9e",
                            borderTop: "1px solid #9f9e9e",
                            padding: "5px",
                          }}
                        ></td> */}
                        <td
                          style={{
                            fontSize: "10px",
                            lineHeight: "16px",
                            fontWeight: 700,
                            color: "#000",
                            margin: "0px",
                            textAlign: "left",
                            borderRight: "1px solid #9f9e9e",
                            borderTop: "1px solid #9f9e9e",
                            padding: "5px",
                          }}
                        >
                          Total
                        </td>
                        <td
                          style={{
                            fontSize: "10px",
                            lineHeight: "16px",
                            fontWeight: 400,
                            color: "#000",
                            margin: "0px",
                            textAlign: "left",
                            borderRight: "1px solid #9f9e9e",
                            borderTop: "1px solid #9f9e9e",
                            padding: "5px",
                          }}
                        ></td>
                        <td
                          style={{
                            fontSize: "10px",
                            lineHeight: "16px",
                            fontWeight: 700,
                            color: "#000",
                            margin: "0px",
                            textAlign: "left",
                            borderRight: "1px solid #9f9e9e",
                            borderTop: "1px solid #9f9e9e",
                            padding: "5px",
                          }}
                        >
                          {Number(printData?.totalQty || 0).toFixed(2)}
                        </td>
                        <td
                          style={{
                            fontSize: "10px",
                            lineHeight: "16px",
                            fontWeight: 400,
                            color: "#000",
                            margin: "0px",
                            textAlign: "left",
                            borderRight: "1px solid #9f9e9e",
                            borderTop: "1px solid #9f9e9e",
                            padding: "5px",
                          }}
                        ></td>
                        <td
                          style={{
                            fontSize: "10px",
                            lineHeight: "16px",
                            fontWeight: 700,
                            color: "#222222",
                            margin: "0px",
                            textAlign: "right",
                            borderTop: "1px solid #9f9e9e",
                            padding: "5px",
                          }}
                        >
                          {Number(printData?.totalAmount || 0).toFixed(2)}
                        </td>
                      </tr>

                      {/* <tr>
                        <td
                          colSpan={4}
                          style={{
                            borderRight: "1px solid #9f9e9e",
                            padding: "0px",
                            borderTop: "1px solid #9f9e9e",
                          }}
                        ></td>
                        <td
                          colSpan={5}
                          style={{
                            textAlign: "right",
                            padding: "0px",
                            borderTop: "1px solid #9f9e9e",
                          }}
                        >
                          <h3
                            style={{
                              margin: "0px",
                              fontSize: "10px",
                              lineHeight: "16px",
                              color: "#222222",
                              fontWeight: 700,
                              padding: "5px",
                            }}
                          >
                            For {companyAddress?.Company_name || ""}
                          </h3>
                          <div style={{ padding: "20px 0px" }} />
                          <h3
                            style={{
                              margin: "0px",
                              fontSize: "10px",
                              lineHeight: "16px",
                              color: "#222222",
                              fontWeight: 600,
                              padding: "5px",
                            }}
                          >
                            Authorised Signatory
                          </h3>
                        </td>
                      </tr> */}
                      <tr>
                        <td colSpan={9}>
                          <table
                            border={0}
                            cellSpacing={0}
                            cellPadding={0}
                            width={"100%"}
                            // style={{ border: "1px solid #9f9e9e" }}
                          >
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    verticalAlign: "top",
                                    color: "#222222",
                                    fontSize: 11,
                                    padding: "1px 0px 0px",
                                  }}
                                >
                                  <table
                                    border={0}
                                    cellSpacing={0}
                                    cellPadding={0}
                                    width={"100%"}
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          colSpan={7}
                                          style={{
                                            borderBottom: "1px solid #9f9e9e",
                                            borderRight: "1px solid #9f9e9e",
                                            fontSize: 11,
                                            color: "#222222",
                                            verticalAlign: "top",
                                            padding: 0,
                                            height: 16,
                                            textAlign: "center",
                                          }}
                                        >
                                          <table
                                            border={0}
                                            cellSpacing={0}
                                            cellPadding={0}
                                            width="100%"
                                          >
                                            <tbody>
                                              <tr>
                                                <td style={{ width: "50%" }}>
                                                  <table>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          fontSize: 10,
                                                          width: "30%",
                                                          padding: 0,
                                                          color: "#222222",
                                                          verticalAlign: "top",
                                                          height: 16,
                                                          textTransform:
                                                            "capitalize",
                                                        }}
                                                      >
                                                        <p
                                                          style={{
                                                            margin: 0,
                                                            padding:
                                                              "0px 6px 0px 6px",
                                                            fontWeight: 600,
                                                          }}
                                                        >
                                                          Transport
                                                        </p>
                                                      </td>
                                                      <td
                                                        style={{
                                                          fontSize: 10,
                                                          width: "5%",
                                                          padding: 0,
                                                          color: "#222222",
                                                          verticalAlign: "top",
                                                          height: 16,
                                                          textTransform:
                                                            "capitalize",
                                                        }}
                                                      >
                                                        <p
                                                          style={{
                                                            margin: 0,
                                                            padding:
                                                              "0px 6px 0px 6px",
                                                            fontWeight: 600,
                                                          }}
                                                        >
                                                          :
                                                        </p>
                                                      </td>
                                                      <td
                                                        style={{
                                                          fontSize: 10,
                                                          padding: 0,
                                                          color: "#222222",
                                                          verticalAlign: "top",
                                                          height: 16,
                                                          textTransform:
                                                            "capitalize",
                                                        }}
                                                      >
                                                        <p
                                                          style={{
                                                            margin: 0,
                                                            padding:
                                                              "0px 6px 0px 6px",
                                                            fontWeight: 600,
                                                          }}
                                                        >
                                                          {
                                                            printData?.transportCarrier
                                                          }
                                                        </p>
                                                      </td>
                                                    </tr>
                                                  </table>
                                                </td>
                                              </tr>

                                              <tr>
                                                <td style={{ width: "50%" }}>
                                                  <table>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          width: "30%",
                                                          fontSize: 10,
                                                          padding: 0,
                                                          color: "#222222",
                                                          verticalAlign: "top",
                                                          height: 16,
                                                          textTransform:
                                                            "capitalize",
                                                        }}
                                                      >
                                                        <p
                                                          style={{
                                                            margin: 0,
                                                            padding:
                                                              "0px 6px 0px 6px",
                                                            fontWeight: 600,
                                                          }}
                                                        >
                                                          E-WAY NO.
                                                        </p>
                                                      </td>
                                                      <td
                                                        style={{
                                                          fontSize: 10,
                                                          padding: 0,
                                                          width: "5%",
                                                          color: "#222222",
                                                          verticalAlign: "top",
                                                          height: 16,
                                                          textTransform:
                                                            "capitalize",
                                                        }}
                                                      >
                                                        <p
                                                          style={{
                                                            margin: 0,
                                                            padding:
                                                              "0px 6px 0px 6px",
                                                            fontWeight: 600,
                                                          }}
                                                        >
                                                          :
                                                        </p>
                                                      </td>
                                                      <td
                                                        style={{
                                                          fontSize: 10,
                                                          padding: 0,
                                                          color: "#222222",
                                                          verticalAlign: "top",
                                                          height: 16,
                                                          textTransform:
                                                            "capitalize",
                                                        }}
                                                      >
                                                        <p
                                                          style={{
                                                            margin: 0,
                                                            padding:
                                                              "0px 6px 0px 6px",
                                                            fontWeight: 600,
                                                          }}
                                                        >
                                                          {
                                                            printData?.ewaybillno
                                                          }
                                                        </p>
                                                      </td>
                                                    </tr>
                                                  </table>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td
                                                  style={{
                                                    padding: "0px",
                                                    borderTop:
                                                      "1px solid #9f9e9e",
                                                  }}
                                                  colSpan={2}
                                                >
                                                  <table>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          fontSize: 9,
                                                          color: "#222222",
                                                          verticalAlign: "top",
                                                          padding: "0px 6px",
                                                          height: 16,
                                                          fontWeight: 600,
                                                          textAlign: "left",
                                                        }}
                                                      >
                                                        IRN No. :{" "}
                                                        <span>
                                                          511RDGFGHHDGIHDGDDFFHFEDDDFIE
                                                          Remaining
                                                        </span>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          width: "70%",
                                                          fontSize: 9,
                                                          color: "#222222",
                                                          padding: "0px 6px",
                                                          verticalAlign: "top",
                                                          height: 16,
                                                          fontWeight: 600,
                                                          textAlign: "left",
                                                        }}
                                                      >
                                                        ACK No. :{" "}
                                                        <span>
                                                          511RDGFGHHDGIHDGDDFFHFEDDDFIE
                                                          Remaining
                                                        </span>
                                                      </td>
                                                      <td
                                                        style={{
                                                          width: "30%",
                                                          fontSize: 9,
                                                          color: "#222222",
                                                          padding: "0px 6px",
                                                          verticalAlign: "top",
                                                          textAlign: "right",
                                                          height: 16,
                                                          fontWeight: 600,
                                                        }}
                                                      >
                                                        E & O.E
                                                      </td>
                                                    </tr>
                                                  </table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                        <td
                                          colSpan={4}
                                          style={{ verticalAlign: "top" }}
                                        >
                                          <table width="100%">
                                            {taxData?.map((tax, index) => (
                                              <tr>
                                                <td
                                                  style={{
                                                    borderBottom:
                                                      "1px solid #9f9e9e",
                                                    borderRight:
                                                      "1px solid #9f9e9e",
                                                    fontSize: 10,
                                                    padding: "2px 6px",
                                                    color: "#222222",
                                                    verticalAlign: "top",
                                                    height: 16,
                                                    textTransform: "uppercase",
                                                    textAlign: "center",
                                                    fontWeight: 600,
                                                    width: "60%",
                                                  }}
                                                >
                                                  {`+${tax?.tax_type} ${
                                                    tax?.taxRate
                                                  } % on ${
                                                    tax?.taxOnAmt ?? ""
                                                  }`}
                                                </td>
                                                <td
                                                  style={{
                                                    borderBottom:
                                                      "1px solid #9f9e9e",
                                                    fontSize: 10,
                                                    padding: "2px 6px",
                                                    color: "#222222",
                                                    verticalAlign: "top",
                                                    height: 16,
                                                    textTransform: "uppercase",
                                                    textAlign: "right",
                                                    fontWeight: 600,
                                                    width: "40%",
                                                  }}
                                                >
                                                  {tax?.amount}
                                                </td>
                                              </tr>
                                            ))}
                                          </table>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          colSpan={7}
                                          style={{
                                            borderBottom: "1px solid #9f9e9e",
                                            borderRight: "1px solid #9f9e9e",
                                            fontSize: 10,
                                            padding: "3px 6px",
                                            color: "#222222",
                                            verticalAlign: "center",
                                            height: 16,
                                            textAlign: "left",
                                            fontWeight: 600,
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          Rupees :{" "}
                                          {printData.totalAmount
                                            ? `${toWords.convert(
                                                printData.totalAmount
                                              )}`
                                            : ""}
                                        </td>
                                        <td
                                          colSpan={3}
                                          style={{
                                            borderBottom: "1px solid #9f9e9e",
                                            borderTop: "1px solid #9f9e9e",
                                            borderRight: "1px solid #9f9e9e",
                                            fontSize: 12,
                                            padding: "3px 6px",
                                            color: "#222222",
                                            verticalAlign: "top",
                                            height: 16,
                                            textTransform: "uppercase",
                                            textAlign: "center",
                                            fontWeight: 600,
                                          }}
                                        >
                                          NET AMOUNT
                                        </td>
                                        <td
                                          style={{
                                            borderBottom: "1px solid #9f9e9e",
                                            borderTop: "1px solid #9f9e9e",
                                            fontSize: 12,
                                            padding: "3px 6px",
                                            color: "#222222",
                                            verticalAlign: "top",
                                            height: 16,
                                            textTransform: "uppercase",
                                            textAlign: "right",
                                            fontWeight: 600,
                                          }}
                                        >
                                          {Number(
                                            printData.totalAmount
                                          ).toFixed(2)}
                                        </td>
                                      </tr>

                                      <tr>
                                        <td
                                          colSpan={7}
                                          style={{
                                            fontSize: 10,
                                            borderRight: "1px solid #9f9e9e",
                                            padding: "2px 6px",
                                            color: "#222222",
                                            verticalAlign: "center",
                                            height: 16,
                                            width: "50%",
                                          }}
                                        ></td>

                                        <td
                                          colSpan={4}
                                          style={{
                                            fontSize: 12,
                                            padding: "2px 6px",
                                            color: "#222222",
                                            height: 16,
                                            width: "25%",
                                            fontWeight: "600",
                                            textAlign: "right",
                                          }}
                                        >
                                          <p style={{ height: 16 }}>
                                            For{" "}
                                            {companyAddress?.Company_name || ""}
                                          </p>
                                          <p style={{ height: 16 }}></p>
                                          <p style={{ height: 16 }}></p>
                                          <p style={{ height: 16 }}></p>
                                          <p style={{ height: 16 }}>
                                            Authorised Signatory
                                          </p>
                                        </td>
                                      </tr>
                                      {/* <tr>
                                <td
                                  colSpan={11}
                                  style={{
                                    borderBottom: "1px solid #9f9e9e",
                                    borderRight: "1px solid #9f9e9e",
                                    fontSize: 12,
                                    padding: "3px 1px 0px",
                                    color: "#222222",
                                    verticalAlign: "top",
                                    height: 16,
                                    textAlign: "left",
                                  }}
                                >
                                  <table
                                    border={0}
                                    cellSpacing={0}
                                    cellPadding={0}
                                  >
                                    <tbody>
                                      
                                     
                                    </tbody>
                                  </table>
                                </td>
                              </tr> */}
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="print-button">
        <Index.Box className="btn_linear">
          <Index.Button
            variant="contained"
            onClick={() => handlePrint()}
            className="linear-btn-main"
          >
            Print
          </Index.Button>
        </Index.Box>
        <div style={{ display: "none" }}>
          <PrintComponent
            printRef={printRef}
            printData={printData}
            companyAddress={companyAddress}
            supplierAddress={supplierAddress}
            productData={productData}
            poData={poData}
            taxData={taxData}
            toWords={toWords}
          />
        </div>
      </div>
    </>
  );
  // }
}

const PrintComponent = (props) => {
  let {
    printRef,
    printData,
    companyAddress,
    supplierAddress,
    productData,
    taxData,
    toWords,
  } = props;

  const dataPerPage = 16;

  const [formattedData, setFormattedData] = useState([]);

  const calculatePageNo = () => {
    let pages = Math.ceil(productData?.length / dataPerPage) || 1;
    const data = Array.from({ length: pages })?.map((_, i) => {
      const page = i + 1;
      const isLastPage = pages - 1 === i;
      const pageTotalQuantity = Number(
        productData
          ?.slice(dataPerPage * i, dataPerPage * (i + 1))
          ?.reduce((acc, curr) => {
            return acc + curr?.qty;
          }, 0) || 0
      ).toFixed(2);
      const totalQty = Number(printData?.totalQty || 0).toFixed(2);
      const emptyData =
        dataPerPage -
        productData?.slice(
          dataPerPage * (Math.ceil(productData?.length / dataPerPage) - 1)
        )?.length;
      return {
        page,
        rows: productData?.slice(dataPerPage * i, dataPerPage * (i + 1)),
        pageTotalQty: isLastPage ? totalQty : pageTotalQuantity,
        isLastPage,
        emptyData,
      };
    });

    setFormattedData(data);
  };

  useEffect(() => {
    calculatePageNo();
  }, [productData, printData]);

  return (
    <div className="">
      <div className="border">
        <div
          className="purchase-order-print-container"
          style={{ margin: 15 }}
          ref={printRef}
        >
          {formattedData?.map((rowData, i) => {
            const { page, pageTotalQty, isLastPage, emptyData } = rowData;
            return (
              <div
                className="purchase-order-print"
                style={{ paddingTop: i !== 0 ? 15 : 0 }}
              >
                <table
                  style={{
                    backgroundColor: "#fff",
                    margin: "auto",
                    border: "1px solid #9f9e9e",
                    width: "100%",
                  }}
                  border={0}
                  cellSpacing={0}
                  cellPadding={0}
                  role="presentation"
                >
                  <thead>
                    <tr>
                      <td
                        colSpan={5}
                        style={{ border: "1px solid #9f9e9e", padding: "10px" }}
                      >
                        <table
                          border={0}
                          cellSpacing={0}
                          cellPadding={0}
                          style={{ border: 0, width: "100%" }}
                        >
                          <tr>
                            <td
                              style={{
                                width: "20%",
                                paddingLeft: "20px",
                              }}
                            >
                              {printData?.companyLogo ? (
                                <img
                                  src={
                                    process.env.REACT_APP_IMAGE_URL +
                                    printData?.companyLogo
                                  }
                                  // height="60px"
                                  // width="100px"
                                  style={{
                                    objectFit: "contain",
                                    height: "100%",
                                    width: "100%",
                                    maxWidth: "100px",
                                    maxHeight: "60px",
                                  }}
                                />
                              ) : (
                                <></>
                              )}
                            </td>
                            <td>
                              <tr>
                                <td
                                  style={{
                                    fontSize: 15,
                                    lineHeight: 1.2,
                                    color: "#666666",
                                    verticalAlign: "top",
                                    borderBottom: 0,
                                    textAlign: "center",
                                    width: "40%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      color: "#222222",
                                      margin: "0px 0px 5px 0px",
                                      fontSize: "25px",
                                    }}
                                  >
                                    {companyAddress?.Company_name}
                                  </h2>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    borderBottom: 0,
                                    fontSize: 11,
                                    lineHeight: 1.2,
                                    padding: "1px 10px 5px",
                                    color: "#666666",
                                    verticalAlign: "middle",
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    textAlign: "center",
                                  }}
                                  className="address"
                                >
                                  <p
                                    style={{
                                      textAlign: "center",
                                      lineHeight: 1.2,
                                      margin: 0,
                                      fontSize: 11,
                                      color: "#666666",
                                    }}
                                  >
                                    {`${companyAddress?.o_add1 || ""}, ${
                                      companyAddress?.o_add2 || ""
                                    }, `}
                                  </p>
                                  <p
                                    style={{
                                      textAlign: "center",
                                      lineHeight: 1.2,
                                      margin: 0,
                                      fontSize: 11,
                                      color: "#666666",
                                    }}
                                  >
                                    {`${companyAddress?.o_add3 || ""}, ${
                                      companyAddress?.cityName || ""
                                    } - ${companyAddress?.o_pin || ""}, ${
                                      companyAddress?.Statename || ""
                                    } - ${companyAddress?.sCode || ""}`}
                                  </p>
                                  <p
                                    style={{
                                      textAlign: "center",
                                      lineHeight: 1.2,
                                      margin: 0,
                                      fontSize: 11,
                                      color: "#666666",
                                    }}
                                  >
                                    Contact No : {printData.companyMobileNo},
                                    Email Id : {printData.companyEmail}
                                  </p>
                                  <p
                                    style={{
                                      textAlign: "center",
                                      lineHeight: 2,
                                      margin: 0,
                                      fontWeight: "700",
                                      fontSize: 11,
                                      color: "#666666",
                                      letterSpacing: "1.2px",
                                    }}
                                  >
                                    GSTIN NO : {printData.companyGSTNo}, PAN NO
                                    :{printData.companyPanNo}
                                  </p>
                                  <p />
                                </td>
                              </tr>
                            </td>
                            <td style={{ textAlign: "right" }}>
                              <img
                                src={QRImage}
                                alt="qr"
                                style={{ height: "70px", width: "70px" }}
                              />
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: "0px" }}>
                        <table
                          style={{
                            width: "100%",
                            backgroundColor: "#efefef",
                            borderBottom: "1px solid #9f9e9e",
                            borderCollapse: "collapse",
                          }}
                        >
                          <tbody>
                            <tr>
                              <td style={{ width: "100%", padding: "3px" }}>
                                <h3
                                  style={{
                                    textTransform: "uppercase",
                                    fontSize: "11px",
                                    lineHeight: "16px",
                                    color: "#000",
                                    margin: "0px",
                                    fontFamily: '"popins-regular", sans-serif',
                                    textAlign: "center",
                                  }}
                                >
                                  DEBIT NOTE
                                </h3>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: "0px" }}>
                        <table
                          style={{
                            borderBottom: "1px solid #9f9e9e",
                            borderCollapse: "collapse",
                            width: "100%",
                          }}
                        >
                          <tbody>
                            <tr>
                              <td
                                className="padding-none border-none"
                                width="50%"
                                style={{
                                  fontSize: 10,
                                  padding: 0,
                                  color: "#666666",
                                  verticalAlign: "top",
                                  border: 0,
                                }}
                              >
                                <table
                                  border={0}
                                  cellSpacing={0}
                                  cellPadding={0}
                                  style={{
                                    width: "100%",
                                    textAlign: "left",
                                  }}
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        className="label"
                                        style={{
                                          borderRight: "1px solid #9f9e9e",
                                          fontSize: 10,
                                          padding: "1px 6px",
                                          color: "#666666",
                                          verticalAlign: "top",
                                        }}
                                      >
                                        <p style={{ margin: "0 0 0px" }}>
                                          <b style={{ color: "#222222" }}>
                                            {`To : ${
                                              printData?.supplier || ""
                                            }`}
                                          </b>
                                        </p>
                                        <p
                                          style={{
                                            margin: "0 0 0px",
                                            minHeight: "18px",
                                          }}
                                        >
                                          {supplierAddress?.address1}
                                        </p>
                                        <p
                                          style={{
                                            margin: "0 0 0px",
                                            minHeight: "18px",
                                          }}
                                        >
                                          {supplierAddress?.address2}
                                        </p>
                                        <p
                                          style={{
                                            margin: "0 0 0px",
                                            minHeight: "18px",
                                          }}
                                        >
                                          {supplierAddress?.address3}
                                        </p>
                                        <p
                                          style={{
                                            margin: "0 0 0px",
                                            minHeight: "18px",
                                          }}
                                        >
                                          {`${
                                            supplierAddress?.cityName || ""
                                          } - ${supplierAddress?.pin || ""}, ${
                                            supplierAddress?.Statename || ""
                                          } - ${supplierAddress?.sCode || ""}`}
                                        </p>
                                        <p
                                          style={{
                                            margin: "0 0 0px",
                                            minHeight: "18px",
                                          }}
                                        >
                                          GSTIN NO :{" "}
                                          {printData?.supplierGSTNo || ""}
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PAN
                                          NO : {printData?.supplierPanNo || ""}
                                        </p>
                                        <p
                                          style={{
                                            margin: "0 0 0px",
                                            minHeight: "18px",
                                          }}
                                        >
                                          Contact No :{" "}
                                          {`${
                                            printData?.supplierMobile || ""
                                          }/${
                                            printData?.supplierOfficeNumber ||
                                            ""
                                          }`}
                                        </p>
                                        {printData?.supplierMSME &&
                                          printData?.supplierMSME != 0 && (
                                            <p
                                              style={{
                                                margin: "0 0 0px",
                                                minHeight: "18px",
                                              }}
                                            >
                                              MSME No :{" "}
                                              {printData?.supplierMSME || ""}
                                            </p>
                                          )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td
                                style={{
                                  padding: "0px",
                                  width: "50%",
                                  verticalAlign: "top",
                                }}
                              >
                                <table style={{ width: "100%" }}>
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          width: "100%",
                                          borderBottom: "1px solid #9f9e9e",
                                          padding: "0px 0px 0px 0px",
                                        }}
                                      >
                                        <table>
                                          <tbody>
                                            <tr>
                                              <td
                                                className="label"
                                                style={{
                                                  fontSize: 10,
                                                  lineHeight: 1.5,
                                                  padding: "3.5px 6px",
                                                  color: "#666666",
                                                  verticalAlign: "top",
                                                  width: "50%",
                                                }}
                                              >
                                                <b style={{ color: "#222222" }}>
                                                  Debit No:
                                                </b>
                                                <span
                                                  style={{
                                                    paddingLeft: "18px",
                                                  }}
                                                >
                                                  {printData?.noteNo}
                                                </span>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                className="label"
                                                style={{
                                                  fontSize: 10,
                                                  lineHeight: 1.5,
                                                  padding: "3.5px 6px",
                                                  color: "#666666",
                                                  verticalAlign: "top",
                                                  width: "50%",
                                                }}
                                              >
                                                <b style={{ color: "#222222" }}>
                                                  Date:
                                                </b>
                                                <span
                                                  style={{
                                                    paddingLeft: "38px",
                                                  }}
                                                >
                                                  {moment(
                                                    printData?.noteDate
                                                  ).format("DD/MM/YYYY")}
                                                </span>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{ padding: "0px" }}
                                              ></td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{ padding: "0px" }}
                                              ></td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{ padding: "0px" }}
                                              ></td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <table style={{ width: "100%" }}>
                                          <tbody>
                                            <tr>
                                              <td
                                                className="label"
                                                style={{
                                                  fontSize: 10,
                                                  lineHeight: 1.5,
                                                  padding: "3.5px 6px",
                                                  color: "#666666",
                                                  verticalAlign: "top",
                                                  width: "50%",
                                                }}
                                              >
                                                <b style={{ color: "#222222" }}>
                                                  NOTE:
                                                </b>
                                                <span
                                                  style={{
                                                    paddingLeft: "32px",
                                                  }}
                                                >
                                                  GOOD RETURN AGAINST
                                                  {/* {poData?.[0]?.date
                                              ? Array.prototype.map
                                                  .call(
                                                    poData,
                                                    function (item) {
                                                      return item?.purchaseNo;
                                                    }
                                                  )
                                                  .join(", ")
                                              : "N/A"} */}
                                                </span>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                className="label"
                                                style={{
                                                  fontSize: 10,
                                                  lineHeight: 1.5,
                                                  padding: "3.5px 6px",
                                                  color: "#666666",
                                                  verticalAlign: "top",
                                                  width: "50%",
                                                }}
                                              >
                                                <b style={{ color: "#222222" }}>
                                                  Bill No:
                                                </b>
                                                <span
                                                  style={{
                                                    paddingLeft: "29px",
                                                  }}
                                                >
                                                  {printData?.againstBillNo}
                                                </span>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                className="label"
                                                style={{
                                                  fontSize: 10,
                                                  lineHeight: 1.5,
                                                  padding: "3.5px 6px",
                                                  color: "#666666",
                                                  verticalAlign: "top",
                                                  width: "50%",
                                                }}
                                              >
                                                <b style={{ color: "#222222" }}>
                                                  Bill Date:
                                                </b>
                                                <span
                                                  style={{
                                                    paddingLeft: "20px",
                                                  }}
                                                >
                                                  {printData?.againstBillDate
                                                    ? moment(
                                                        printData?.againstBillDate
                                                      ).format("DD-MM-YYYY")
                                                    : "-"}
                                                </span>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ padding: "0px" }}>
                        <table
                          style={{ width: "100%", borderCollapse: "collapse" }}
                        >
                          <thead>
                            <tr>
                              <th
                                style={{
                                  fontSize: "11px",
                                  lineHeight: "16px",
                                  color: "#222222",
                                  margin: "0px",
                                  textAlign: "left",
                                  borderRight: "1px solid #9f9e9e",
                                  borderBottom: "1px solid #9f9e9e",
                                  backgroundColor: "#efefef",
                                  padding: "3px 6px",
                                  width: "3%",
                                }}
                              >
                                Sr.
                              </th>
                              <th
                                style={{
                                  fontSize: "11px",
                                  lineHeight: "16px",
                                  color: "#222222",
                                  margin: "0px",
                                  textAlign: "left",
                                  borderRight: "1px solid #9f9e9e",
                                  borderBottom: "1px solid #9f9e9e",
                                  backgroundColor: "#efefef",
                                  padding: "3px 6px",
                                  width: "33%",
                                }}
                              >
                                Product
                              </th>
                              <th
                                style={{
                                  fontSize: "11px",
                                  lineHeight: "16px",
                                  color: "#222222",
                                  margin: "0px",
                                  textAlign: "left",
                                  borderRight: "1px solid #9f9e9e",
                                  borderBottom: "1px solid #9f9e9e",
                                  backgroundColor: "#efefef",
                                  padding: "3px 6px",
                                  width: "8%",
                                }}
                              >
                                HSN
                              </th>
                              <th
                                style={{
                                  fontSize: "11px",
                                  lineHeight: "16px",
                                  color: "#222222",
                                  margin: "0px",
                                  textAlign: "left",
                                  borderRight: "1px solid #9f9e9e",
                                  borderBottom: "1px solid #9f9e9e",
                                  backgroundColor: "#efefef",
                                  padding: "3px 6px",
                                  width: "8%",
                                }}
                              >
                                Colour
                              </th>
                              <th
                                style={{
                                  fontSize: "11px",
                                  lineHeight: "16px",
                                  color: "#222222",
                                  margin: "0px",
                                  textAlign: "left",
                                  borderRight: "1px solid #9f9e9e",
                                  borderBottom: "1px solid #9f9e9e",
                                  backgroundColor: "#efefef",
                                  padding: "3px 6px",
                                  width: "8%",
                                }}
                              >
                                Size
                              </th>
                              <th
                                style={{
                                  fontSize: "11px",
                                  lineHeight: "16px",
                                  color: "#222222",
                                  margin: "0px",
                                  textAlign: "left",
                                  borderRight: "1px solid #9f9e9e",
                                  borderBottom: "1px solid #9f9e9e",
                                  backgroundColor: "#efefef",
                                  padding: "3px 6px",
                                  width: "8%",
                                }}
                              >
                                Unit
                              </th>
                              <th
                                style={{
                                  fontSize: "11px",
                                  lineHeight: "16px",
                                  color: "#222222",
                                  margin: "0px",
                                  textAlign: "left",
                                  borderRight: "1px solid #9f9e9e",
                                  borderBottom: "1px solid #9f9e9e",
                                  backgroundColor: "#efefef",
                                  padding: "3px 6px",
                                  width: "8%",
                                }}
                              >
                                Qty
                              </th>
                              <th
                                style={{
                                  fontSize: "11px",
                                  lineHeight: "16px",
                                  color: "#222222",
                                  margin: "0px",
                                  textAlign: "left",
                                  borderRight: "1px solid #9f9e9e",
                                  borderBottom: "1px solid #9f9e9e",
                                  backgroundColor: "#efefef",
                                  padding: "3px 6px",
                                  width: "8%",
                                }}
                              >
                                Rate
                              </th>
                              <th
                                style={{
                                  fontSize: "11px",
                                  lineHeight: "16px",
                                  color: "#222222",
                                  margin: "0px",
                                  textAlign: "right",
                                  // borderRight: "1px solid #9f9e9e",
                                  borderBottom: "1px solid #9f9e9e",
                                  backgroundColor: "#efefef",
                                  padding: "3px 6px",
                                  width: "8%",
                                }}
                              >
                                Amount
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {productData
                              ?.slice(dataPerPage * i, dataPerPage * (i + 1))
                              .map((row, index) => (
                                <tr
                                  style={{ borderBottom: "1px solid #9f9e9e" }}
                                >
                                  <td
                                    style={{
                                      fontSize: 9,
                                      lineHeight: "16px",
                                      fontWeight: 400,
                                      color: "#222222",
                                      margin: "0px",
                                      textAlign: "left",
                                      borderRight: "1px solid #9f9e9e",
                                      padding: "5px",
                                    }}
                                  >
                                    {index + 1}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: 9,
                                      lineHeight: "16px",
                                      fontWeight: 400,
                                      color: "#222222",
                                      margin: "0px",
                                      textAlign: "left",
                                      borderRight: "1px solid #9f9e9e",
                                      padding: "5px",
                                    }}
                                  >
                                    {row?.ProductName || ""}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: 9,
                                      lineHeight: "16px",
                                      fontWeight: 400,
                                      color: "#222222",
                                      margin: "0px",
                                      textAlign: "left",
                                      borderRight: "1px solid #9f9e9e",
                                      padding: "5px",
                                    }}
                                  >
                                    {row?.HSNCode || ""}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: 9,
                                      lineHeight: "16px",
                                      fontWeight: 400,
                                      color: "#222222",
                                      margin: "0px",
                                      textAlign: "left",
                                      borderRight: "1px solid #9f9e9e",
                                      padding: "5px",
                                    }}
                                  >
                                    {row?.color_name || ""}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: 9,
                                      lineHeight: "16px",
                                      fontWeight: 400,
                                      color: "#222222",
                                      margin: "0px",
                                      textAlign: "left",
                                      borderRight: "1px solid #9f9e9e",
                                      padding: "5px",
                                    }}
                                  >
                                    {row?.size_name || ""}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: 9,
                                      lineHeight: "16px",
                                      fontWeight: 400,
                                      color: "#222222",
                                      margin: "0px",
                                      textAlign: "left",
                                      borderRight: "1px solid #9f9e9e",
                                      padding: "5px",
                                    }}
                                  >
                                    {row?.unitCode || ""}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: 9,
                                      lineHeight: "16px",
                                      fontWeight: 400,
                                      color: "#222222",
                                      margin: "0px",
                                      textAlign: "left",
                                      borderRight: "1px solid #9f9e9e",
                                      padding: "5px",
                                    }}
                                  >
                                    {Number(row?.qty || 0).toFixed(2)}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: 9,
                                      lineHeight: "16px",
                                      fontWeight: 400,
                                      color: "#222222",
                                      margin: "0px",
                                      textAlign: "left",
                                      borderRight: "1px solid #9f9e9e",
                                      padding: "5px",
                                    }}
                                  >
                                    {Number(row?.rate || 0).toFixed(2)}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: 9,
                                      lineHeight: "16px",
                                      fontWeight: 400,
                                      color: "#222222",
                                      margin: "0px",
                                      textAlign: "left",
                                      // borderRight: "1px solid #9f9e9e",
                                      padding: "5px",
                                      textAlign: "right",
                                    }}
                                  >
                                    {Number(row?.amount || 0).toFixed(2)}
                                  </td>
                                </tr>
                              ))}

                            {isLastPage &&
                              Array.from({ length: emptyData }).map(
                                (row, index) => (
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: "10px",
                                        lineHeight: "16px",
                                        fontWeight: 400,
                                        color: "transparent",
                                        margin: "0px",
                                        textAlign: "left",
                                        padding: "5px",
                                        height: 25,
                                        width: 20,
                                        maxWidth: 20,
                                      }}
                                      colSpan={4}
                                    >
                                      1
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "10px",
                                        lineHeight: "16px",
                                        fontWeight: 400,
                                        color: "transparent",
                                        margin: "0px",
                                        textAlign: "left",
                                        padding: "5px",
                                        borderLeft: "1px solid #9f9e9e",
                                        height: 25,
                                        width: 20,
                                        maxWidth: 20,
                                      }}
                                    >
                                      1
                                    </td>
                                  </tr>
                                )
                              )}

                            <tr style={{ borderBottom: "1px solid #9f9e9e" }}>
                              <td
                                colSpan={4}
                                style={{
                                  fontSize: "10px",
                                  lineHeight: "16px",
                                  fontWeight: 400,
                                  color: "#000",
                                  margin: "0px",
                                  textAlign: "left",
                                  borderRight: "1px solid #9f9e9e",
                                  borderTop: "1px solid #9f9e9e",
                                  padding: "5px",
                                }}
                              >
                                <b>MSME NO.:{printData?.MSMENo}</b>
                              </td>
                              {/* <td
                          style={{
                            fontSize: "10px",
                            lineHeight: "16px",
                            fontWeight: 400,
                            color: "#000",
                            margin: "0px",
                            textAlign: "left",
                            borderRight: "1px solid #9f9e9e",
                            borderTop: "1px solid #9f9e9e",
                            padding: "5px",
                          }}
                        ></td>
                        <td
                          style={{
                            fontSize: "10px",
                            lineHeight: "16px",
                            fontWeight: 400,
                            color: "#000",
                            margin: "0px",
                            textAlign: "left",
                            borderRight: "1px solid #9f9e9e",
                            borderTop: "1px solid #9f9e9e",
                            padding: "5px",
                          }}
                        ></td>
                        <td
                          style={{
                            fontSize: "10px",
                            lineHeight: "16px",
                            fontWeight: 400,
                            color: "#000",
                            margin: "0px",
                            textAlign: "left",
                            borderRight: "1px solid #9f9e9e",
                            borderTop: "1px solid #9f9e9e",
                            padding: "5px",
                          }}
                        ></td> */}
                              <td
                                style={{
                                  fontSize: "10px",
                                  lineHeight: "16px",
                                  fontWeight: 700,
                                  color: "#000",
                                  margin: "0px",
                                  textAlign: "left",
                                  borderRight: "1px solid #9f9e9e",
                                  borderTop: "1px solid #9f9e9e",
                                  padding: "5px",
                                }}
                              >
                                Total
                              </td>
                              <td
                                style={{
                                  fontSize: "10px",
                                  lineHeight: "16px",
                                  fontWeight: 400,
                                  color: "#000",
                                  margin: "0px",
                                  textAlign: "left",
                                  borderRight: "1px solid #9f9e9e",
                                  borderTop: "1px solid #9f9e9e",
                                  padding: "5px",
                                }}
                              ></td>
                              <td
                                style={{
                                  fontSize: "10px",
                                  lineHeight: "16px",
                                  fontWeight: 700,
                                  color: "#000",
                                  margin: "0px",
                                  textAlign: "left",
                                  borderRight: "1px solid #9f9e9e",
                                  borderTop: "1px solid #9f9e9e",
                                  padding: "5px",
                                }}
                              >
                                {Number(pageTotalQty).toFixed(2)}
                              </td>
                              <td
                                style={{
                                  fontSize: "10px",
                                  lineHeight: "16px",
                                  fontWeight: 400,
                                  color: "#000",
                                  margin: "0px",
                                  textAlign: "left",
                                  borderRight: "1px solid #9f9e9e",
                                  borderTop: "1px solid #9f9e9e",
                                  padding: "5px",
                                }}
                              ></td>
                              <td
                                style={{
                                  fontSize: "10px",
                                  lineHeight: "16px",
                                  fontWeight: 700,
                                  color: "#222222",
                                  margin: "0px",
                                  textAlign: "right",
                                  borderTop: "1px solid #9f9e9e",
                                  padding: "5px",
                                }}
                              >
                                {isLastPage
                                  ? Number(printData?.totalAmount || 0).toFixed(
                                      2
                                    )
                                  : ""}
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={9}>
                                <table
                                  border={0}
                                  cellSpacing={0}
                                  cellPadding={0}
                                  width={"100%"}
                                  // style={{ border: "1px solid #9f9e9e" }}
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          verticalAlign: "top",
                                          color: "#222222",
                                          fontSize: 11,
                                          padding: "1px 0px 0px",
                                        }}
                                      >
                                        <table
                                          border={0}
                                          cellSpacing={0}
                                          cellPadding={0}
                                          width={"100%"}
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                colSpan={7}
                                                style={{
                                                  borderBottom:
                                                    "1px solid #9f9e9e",
                                                  borderRight:
                                                    "1px solid #9f9e9e",
                                                  fontSize: 11,
                                                  color: "#222222",
                                                  verticalAlign: "top",
                                                  padding: 0,
                                                  height: 16,
                                                  textAlign: "center",
                                                }}
                                              >
                                                <table
                                                  border={0}
                                                  cellSpacing={0}
                                                  cellPadding={0}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{ width: "50%" }}
                                                      >
                                                        <table>
                                                          <tr>
                                                            <td
                                                              style={{
                                                                fontSize: 10,
                                                                width: "30%",
                                                                padding: 0,
                                                                color:
                                                                  "#222222",
                                                                verticalAlign:
                                                                  "top",
                                                                height: 16,
                                                                textTransform:
                                                                  "capitalize",
                                                              }}
                                                            >
                                                              <p
                                                                style={{
                                                                  margin: 0,
                                                                  padding:
                                                                    "0px 6px 0px 6px",
                                                                  fontWeight: 600,
                                                                }}
                                                              >
                                                                Transport
                                                              </p>
                                                            </td>
                                                            <td
                                                              style={{
                                                                fontSize: 10,
                                                                width: "5%",
                                                                padding: 0,
                                                                color:
                                                                  "#222222",
                                                                verticalAlign:
                                                                  "top",
                                                                height: 16,
                                                                textTransform:
                                                                  "capitalize",
                                                              }}
                                                            >
                                                              <p
                                                                style={{
                                                                  margin: 0,
                                                                  padding:
                                                                    "0px 6px 0px 6px",
                                                                  fontWeight: 600,
                                                                }}
                                                              >
                                                                :
                                                              </p>
                                                            </td>
                                                            <td
                                                              style={{
                                                                fontSize: 10,
                                                                padding: 0,
                                                                color:
                                                                  "#222222",
                                                                verticalAlign:
                                                                  "top",
                                                                height: 16,
                                                                textTransform:
                                                                  "capitalize",
                                                              }}
                                                            >
                                                              <p
                                                                style={{
                                                                  margin: 0,
                                                                  padding:
                                                                    "0px 6px 0px 6px",
                                                                  fontWeight: 600,
                                                                }}
                                                              >
                                                                {
                                                                  printData?.transportCarrier
                                                                }
                                                              </p>
                                                            </td>
                                                          </tr>
                                                        </table>
                                                      </td>
                                                    </tr>

                                                    <tr>
                                                      <td
                                                        style={{ width: "50%" }}
                                                      >
                                                        <table>
                                                          <tr>
                                                            <td
                                                              style={{
                                                                width: "30%",
                                                                fontSize: 10,
                                                                padding: 0,
                                                                color:
                                                                  "#222222",
                                                                verticalAlign:
                                                                  "top",
                                                                height: 16,
                                                                textTransform:
                                                                  "capitalize",
                                                              }}
                                                            >
                                                              <p
                                                                style={{
                                                                  margin: 0,
                                                                  padding:
                                                                    "0px 6px 0px 6px",
                                                                  fontWeight: 600,
                                                                }}
                                                              >
                                                                E-WAY NO.
                                                              </p>
                                                            </td>
                                                            <td
                                                              style={{
                                                                fontSize: 10,
                                                                padding: 0,
                                                                width: "5%",
                                                                color:
                                                                  "#222222",
                                                                verticalAlign:
                                                                  "top",
                                                                height: 16,
                                                                textTransform:
                                                                  "capitalize",
                                                              }}
                                                            >
                                                              <p
                                                                style={{
                                                                  margin: 0,
                                                                  padding:
                                                                    "0px 6px 0px 6px",
                                                                  fontWeight: 600,
                                                                }}
                                                              >
                                                                :
                                                              </p>
                                                            </td>
                                                            <td
                                                              style={{
                                                                fontSize: 10,
                                                                padding: 0,
                                                                color:
                                                                  "#222222",
                                                                verticalAlign:
                                                                  "top",
                                                                height: 16,
                                                                textTransform:
                                                                  "capitalize",
                                                              }}
                                                            >
                                                              <p
                                                                style={{
                                                                  margin: 0,
                                                                  padding:
                                                                    "0px 6px 0px 6px",
                                                                  fontWeight: 600,
                                                                }}
                                                              >
                                                                {
                                                                  printData?.ewaybillno
                                                                }
                                                              </p>
                                                            </td>
                                                          </tr>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          padding: "0px",
                                                          borderTop:
                                                            "1px solid #9f9e9e",
                                                        }}
                                                        colSpan={2}
                                                      >
                                                        <table>
                                                          <tr>
                                                            <td
                                                              style={{
                                                                fontSize: 9,
                                                                color:
                                                                  "#222222",
                                                                verticalAlign:
                                                                  "top",
                                                                padding:
                                                                  "0px 6px",
                                                                height: 16,
                                                                fontWeight: 600,
                                                                textAlign:
                                                                  "left",
                                                              }}
                                                            >
                                                              IRN No. :{" "}
                                                              <span>
                                                                511RDGFGHHDGIHDGDDFFHFEDDDFIE
                                                                Remaining
                                                              </span>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td
                                                              style={{
                                                                width: "70%",
                                                                fontSize: 9,
                                                                color:
                                                                  "#222222",
                                                                padding:
                                                                  "0px 6px",
                                                                verticalAlign:
                                                                  "top",
                                                                height: 16,
                                                                fontWeight: 600,
                                                                textAlign:
                                                                  "left",
                                                              }}
                                                            >
                                                              ACK No. :{" "}
                                                              <span>
                                                                511RDGFGHHDGIHDGDDFFHFEDDDFIE
                                                                Remaining
                                                              </span>
                                                            </td>
                                                            <td
                                                              style={{
                                                                width: "30%",
                                                                fontSize: 9,
                                                                color:
                                                                  "#222222",
                                                                padding:
                                                                  "0px 6px",
                                                                verticalAlign:
                                                                  "top",
                                                                textAlign:
                                                                  "right",
                                                                height: 16,
                                                                fontWeight: 600,
                                                              }}
                                                            >
                                                              E & O.E
                                                            </td>
                                                          </tr>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                              <td
                                                colSpan={4}
                                                style={{ verticalAlign: "top" }}
                                              >
                                                <table width="100%">
                                                  {taxData?.map(
                                                    (tax, index) => (
                                                      <tr>
                                                        <td
                                                          style={{
                                                            borderBottom:
                                                              "1px solid #9f9e9e",
                                                            borderRight:
                                                              "1px solid #9f9e9e",
                                                            fontSize: 10,
                                                            padding: "2px 6px",
                                                            color: "#222222",
                                                            verticalAlign:
                                                              "top",
                                                            height: 16,
                                                            textTransform:
                                                              "uppercase",
                                                            textAlign: "center",
                                                            fontWeight: 600,
                                                            width: "60%",
                                                          }}
                                                        >
                                                          {`+${tax?.tax_type} ${
                                                            tax?.taxRate
                                                          } % on ${
                                                            tax?.taxOnAmt ?? ""
                                                          }`}
                                                        </td>
                                                        <td
                                                          style={{
                                                            borderBottom:
                                                              "1px solid #9f9e9e",
                                                            fontSize: 10,
                                                            padding: "2px 6px",
                                                            color: "#222222",
                                                            verticalAlign:
                                                              "top",
                                                            height: 16,
                                                            textTransform:
                                                              "uppercase",
                                                            textAlign: "right",
                                                            fontWeight: 600,
                                                            width: "40%",
                                                          }}
                                                        >
                                                          {tax?.amount}
                                                        </td>
                                                      </tr>
                                                    )
                                                  )}
                                                </table>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                colSpan={7}
                                                style={{
                                                  borderBottom:
                                                    "1px solid #9f9e9e",
                                                  borderRight:
                                                    "1px solid #9f9e9e",
                                                  fontSize: 10,
                                                  padding: "3px 6px",
                                                  color: "#222222",
                                                  verticalAlign: "center",
                                                  height: 16,
                                                  textAlign: "left",
                                                  fontWeight: 600,
                                                  textTransform: "capitalize",
                                                }}
                                              >
                                                Rupees :{" "}
                                                {printData.totalAmount
                                                  ? `${toWords.convert(
                                                      printData.totalAmount
                                                    )}`
                                                  : ""}
                                              </td>
                                              <td
                                                colSpan={3}
                                                style={{
                                                  borderBottom:
                                                    "1px solid #9f9e9e",
                                                  borderTop:
                                                    "1px solid #9f9e9e",
                                                  borderRight:
                                                    "1px solid #9f9e9e",
                                                  fontSize: 12,
                                                  padding: "3px 6px",
                                                  color: "#222222",
                                                  verticalAlign: "top",
                                                  height: 16,
                                                  textTransform: "uppercase",
                                                  textAlign: "center",
                                                  fontWeight: 600,
                                                }}
                                              >
                                                NET AMOUNT
                                              </td>
                                              <td
                                                style={{
                                                  borderBottom:
                                                    "1px solid #9f9e9e",
                                                  borderTop:
                                                    "1px solid #9f9e9e",
                                                  fontSize: 12,
                                                  padding: "3px 6px",
                                                  color: "#222222",
                                                  verticalAlign: "top",
                                                  height: 16,
                                                  textTransform: "uppercase",
                                                  textAlign: "right",
                                                  fontWeight: 600,
                                                }}
                                              >
                                                {Number(
                                                  printData.totalAmount
                                                ).toFixed(2)}
                                              </td>
                                            </tr>

                                            <tr>
                                              <td
                                                colSpan={7}
                                                style={{
                                                  fontSize: 10,
                                                  borderRight:
                                                    "1px solid #9f9e9e",
                                                  padding: "2px 6px",
                                                  color: "#222222",
                                                  verticalAlign: "center",
                                                  height: 16,
                                                  width: "50%",
                                                }}
                                              ></td>

                                              <td
                                                colSpan={4}
                                                style={{
                                                  fontSize: 12,
                                                  padding: "2px 6px",
                                                  color: "#222222",
                                                  height: 16,
                                                  width: "25%",
                                                  fontWeight: "600",
                                                  textAlign: "right",
                                                }}
                                              >
                                                <p style={{ height: 16 }}>
                                                  For{" "}
                                                  {companyAddress?.Company_name ||
                                                    ""}
                                                </p>
                                                <p style={{ height: 16 }}></p>
                                                <p style={{ height: 16 }}></p>
                                                <p style={{ height: 16 }}></p>
                                                <p style={{ height: 16 }}>
                                                  Authorised Signatory
                                                </p>
                                              </td>
                                            </tr>
                                            {/* <tr>
                                <td
                                  colSpan={11}
                                  style={{
                                    borderBottom: "1px solid #9f9e9e",
                                    borderRight: "1px solid #9f9e9e",
                                    fontSize: 12,
                                    padding: "3px 1px 0px",
                                    color: "#222222",
                                    verticalAlign: "top",
                                    height: 16,
                                    textAlign: "left",
                                  }}
                                >
                                  <table
                                    border={0}
                                    cellSpacing={0}
                                    cellPadding={0}
                                  >
                                    <tbody>
                                      
                                     
                                    </tbody>
                                  </table>
                                </td>
                              </tr> */}
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {!isLastPage && (
                  <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <thead>
                      <tr>
                        <th
                          style={{
                            fontSize: "15px",
                            lineHeight: "20px",
                            fontWeight: 500,
                            color: "#666666",
                            margin: "0px",
                            textAlign: "center",
                            padding: "5px",
                            paddingTop: 15,
                          }}
                          colSpan={9}
                        >
                          {`Continue to page ${i + 2}`}
                        </th>
                      </tr>
                    </thead>
                  </table>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
