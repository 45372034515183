import axios from "axios";
import moment from "moment";
const BASE_URL = process.env.REACT_APP_BASE_URL;
// const BASE_URL = "http://192.168.1.86:3011/api";

const setHeadersWithAccessToken = (token) => {
  axios.defaults.headers.post["Authorization"] = `Bearer ${token}`;
};

const setHeadersWithDeleteAccessToken = (token) => {
  axios.defaults.headers.delete["Authorization"] = `Bearer ${token}`;
};

const setHeadersWithContentType = () => {
  axios.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded";
};

const setHeadersWithContentType2 = () => {
  axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
};

const setGetHeadersWithAccessToken = (
  token,
  selectedYearStart = "",
  selectedYearEnd = ""
) => {
  axios.defaults.headers.get["Authorization"] = `Bearer ${token}`;
  axios.defaults.headers.get["selectedYearStart"] =
    moment(selectedYearStart).format("YYYY-MM-DD");
  axios.defaults.headers.get["selectedYearEnd"] =
    moment(selectedYearEnd).format("YYYY-MM-DD");
};

const handleError = (error) => {
  for (const key in error) {
    if (Object.hasOwnProperty.call(error, key)) {
      const element = error["response"];
      return { message: element?.data?.message, status: element?.status };
    }
  }
};

export const loginApiHandler = async (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/admin/login`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const forgetPasswordApiHandler = async (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/admin/forgotPassword`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const changePasswordApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/changePassword`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const editAdminProfileApiHandler = async (params, token) => {
  setHeadersWithContentType2();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/EditProfile`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const companyListApiHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/list`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const viewCompanyListApiHandler = async (company_Id) => {
  setHeadersWithContentType();
  return axios
    .get(`${BASE_URL}/admin/company/viewCompany/${company_Id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const addCompanyApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/createCompany`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const editCompanyApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/editCompany`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const countryListApiHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/countrylist`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const stateListApiHandler = async (countryId, token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/statelist/${countryId}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const officeCityListApiHandler = async (stateId, token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/city/${stateId}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const factoryCityListApiHandler = async (stateId, token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/city/${stateId}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const companyApproveRejectApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/CompanyStatus`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const addAreaMasterApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/area`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const areaMasterListApiHandler = async (token, id, all) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(
      `${BASE_URL}/admin/company/area${
        id ? `?id=${id}` : `?all=${!all ? 1 : 0}`
      }`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const areaMasterApproveRejectApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/area`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const areaMasterDeleteApiHandler = async (id, token) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/area/?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const addProcessMasterApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/process`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const processMasterListApiHandler = async (token, all) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/process?all=${!all ? 1 : 0}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const processMasterApproveRejectApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/process`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const processMasterDeleteApiHandler = async (id, token) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/process/?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const addGodwonMasterApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/godownMaster`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const godwonMasterListApiHandler = async (token, all) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/godownMaster?all=${!all ? 1 : 0}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const godwonMasterApproveRejectApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/godownMaster`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const godwonMasterDeleteApiHandler = async (id, token) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/godownMaster/?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const addSizeMasterApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/size`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const sizeMasterListApiHandler = async (token, all) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/size?all=${!all ? 1 : 0}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const sizeMasterListWebviewApiHandler = async (all, companyId) => {
  setHeadersWithContentType();
  // setGetHeadersWithAccessToken(token);
  return axios
    .get(
      `${BASE_URL}/admin/company/size-webview?all=${
        !all ? 1 : 0
      }&companyId=${companyId}`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const sizeMasterApproveRejectApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/size`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const sizeMasterDeleteApiHandler = async (id, token) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/size/?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const addColourMasterApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/color`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const colourMasterListApiHandler = async (token, all) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/color?all=${!all ? 1 : 0}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const colourMasterApproveRejectApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/color`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const colourMasterDeleteApiHandler = async (id, token) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/color/?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const addUnitMasterApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/unitMaster`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const unitMasterListApiHandler = async (token, all) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/unitMaster?all=${!all ? 1 : 0}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const unitMasterApproveRejectApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/unitMaster`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const UnitMasterDeleteApiHandler = async (id, token) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/unitMaster/?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const addNarrationMasterApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/narrationMaster`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const narrationMasterListApiHandler = async (token, all) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/narrationMaster?all=${!all ? 1 : 0}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const narrationMasterApproveRejectApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/narrationMaster`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const narrationMasterDeleteApiHandler = async (id, token) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/narrationMaster/?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const addSetMasterApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/setWiseSizeMaster`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const setMasterListApiHandler = async (token, all) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/setMaster?all=${!all ? 1 : 0}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getAllColourMasterApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/setWiseSizeMaster?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const setMasterApproveRejectApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/setMaster`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const setMasterDeleteApiHandler = async (id, token) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/setMaster/?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const companyTypeListApiHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/companyTypeslist`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const industryTypeListApiHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/IndustryTypeslist`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getCompanyProfileApiHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/profile`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const allCityListApiHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/citylist`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const addCountryMasterApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/countryMaster`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const addCityMasterApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/cityMaster`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const cityMasterListApiHandler = async (token, all = "") => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/cityMaster?all=${!all ? 1 : 0}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const cityApproveRejectApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/cityMaster`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getAdminCountryListApiHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/countryMaster`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const countryApproveRejectApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/countryMaster`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const countryMasterDeleteApiHandler = async (id, token) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/countryMaster/?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const addStateMasterApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/stateMaster`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const stateMasterListApiHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/stateMaster`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const stateApproveRejectApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/stateMaster`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const stateMasterDeleteApiHandler = async (id, token) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/stateMaster/?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const cityStateListApiHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/activeState`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const cityMasterDeleteApiHandler = async (id, token) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/cityMaster/?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const productGroupMasterApiHandler = async (token, id = "", all) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .get(
      `${BASE_URL}/admin/company/productGroupMaster${
        id ? `/?id=${id}` : `?all=${!all ? 1 : 0}`
      }`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const addProductGroupMasterApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/productGroupMaster`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const productGroupMasterApproveRejectApiHandler = async (
  params,
  token
) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/productGroupMaster`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const DeleteProductGroupMasterApiHandler = async (id, token) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/productGroupMaster/?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const addProductCategoryMasterApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/productCategory`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const productCategoryMasterListApiHandler = async (token, all) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/productCategory?all=${!all ? 1 : 0}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const productCategoryMasterApproveRejectApiHandler = async (
  params,
  token
) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/productCategory`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const productCategoryMasterDeleteApiHandler = async (id, token) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/productCategory/?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getHSNMasterListApiHandler = async (token, all) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/HSNMaster?all=${!all ? 1 : 0}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const addHSNMasterApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/HSNMaster`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const HSNMasterApproveRejectApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/HSNMaster`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const HSNMasterDeleteApiHandler = async (id, token) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/HSNMaster/?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const addProductMasterApiHandler = async (params, token) => {
  setHeadersWithContentType2();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/productMaster`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getProductMasterApiHandler = async (token, id = "", all) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(
      `${BASE_URL}/admin/company/productMaster${
        id ? `?id=${id}` : `?all=${!all ? 1 : 0}`
      }`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const zoneMasterListApiHandler = async (token, all) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/zoneMaster?all=${!all ? 1 : 0}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const deleteProductMasterApiHandler = async (id, token) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/productMaster?product_code=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const ApproveRejectProductMasterApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/productMaster`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const zoneApproveRejectApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/zoneMaster`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const zoneMasterDeleteApiHandler = async (id, token) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/zoneMaster/?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const addZoneMasterApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/zoneMaster`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getOpeningStockMasterApiHandler = async (token, all) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/openingStock?all=${!all ? 1 : 0}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const addOpeningStockMasterApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/openingStock`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const approveRejectOpeningStockMasterApiHandler = async (
  params,
  token
) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/openingStock`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const deleteOpeningStockMasterApiHandler = async (id, token) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/openingStock/?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const addFinishStockApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/finishOpeningStock`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const FinishStockMasterListApiHandler = async (token, all) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/finishOpeningStock?all=${!all ? 1 : 0}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const FinishStockMasterDeleteApiHandler = async (id, token) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/finishOpeningStock?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const approveRejectFinishStockMasterApiHandler = async (
  params,
  token
) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/finishOpeningStock`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const taxCategoryMasterListApiHandler = async (token, id = "", all) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(
      `${BASE_URL}/admin/company/taxCategoryMaster${
        id ? `?id=${id}` : `?all=${!all ? 1 : 0}`
      }`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const taxCategoryMasterDeleteApiHandler = async (id, token) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/taxCategoryMaster?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const approveRejectTaxCategoryMasterApiHandler = async (
  params,
  token
) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/taxCategoryMaster`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const addTaxCategoryMasterApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/taxCategoryMaster`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getActiveTaxMasterListApiHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/taxMaster`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const allTaxMasterListApiHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/taxMaster/?all=1`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const addTaxMasterApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/taxMaster`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const taxMasterApproveRejectApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/taxMaster`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const taxMasterDeleteApiHandler = async (id, token) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/taxMaster/?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getTaxTypeListApiHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/taxTypes`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getCalculationsTypeListApiHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/calculationsType`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const itemSizePriceApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/item-size-price-list`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const itemSizePriceListApiHandler = async (token, type, id, all) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(
      `${BASE_URL}/admin/company/item-size-price-list${
        id ? `?id=${id}` : `?all=${!all ? 1 : 0}&type=${type}`
      }`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const approveRejectitemSizePriceListApiHandler = async (
  params,
  token
) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/item-size-price-list`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const SizePriceListDeleteApiHandler = async (id, token) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/item-size-price-list?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getActiveAccountGroupMasterListApiHandler = async (
  token,
  id = "",
  all
) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(
      `${BASE_URL}/admin/company/accountGroupMaster${
        id ? `?id=${id}` : `?all=${!all ? 1 : 0}`
      }`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const addAccountGroupMasterApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/accountGroupMaster`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getActiveHeadGroupMasterListApiHandler = async (
  token,
  id = "",
  all
) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(
      `${BASE_URL}/list/heading-group${
        id ? `?id=${id}` : `?all=${!all ? 1 : 0}`
      }`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const approveRejectAccountGroupMasterApiHandler = async (
  params,
  token
) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/accountGroupMaster`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const accountGroupMasterDeleteApiHandler = async (id, token) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/accountGroupMaster?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const checkDuplicateDataApiHandler = async (typeName, value, token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/find-unique/?type=${typeName}&value=${value}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const departmentMasterListApiHandler = async (token, all) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/department-master?all=${all ? 1 : 0}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const departmentMasterApproveRejectApiHandler = async (
  params,
  token
) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/department-master`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const departmentMasterDeleteApiHandler = async (id, token) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/department-master/?Id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const addDepartmentMasterApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/department-master`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getCostGroupMasterListApiHandler = async (token, all) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/cost-group-master?all=${all ? 1 : 0}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const costGroupMasterApproveRejectApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/cost-group-master`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const costGroupMasterDeleteApiHandler = async (id, token) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/cost-group-master/?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const addCostGroupMasterApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/cost-group-master`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getFixedAssetMasterListApiHandler = async (token, all) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/fixed-assets-master?all=${all ? 1 : 0}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const fixedAssetMasterApproveRejectApiHandler = async (
  params,
  token
) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/fixed-assets-master`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const fixedAssetMasterDeleteApiHandler = async (id, token) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/fixed-assets-master?Id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const addFixedAssetsMasterApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/fixed-assets-master`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getAccountMasterListApiHandler = async (token, params, all) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(
      `${BASE_URL}/admin/company/account-ledger-master${
        params ? `?id=${params}` : `?all=${all ? 1 : 0}`
      }`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const accountMasterApproveRejectApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/account-ledger-master`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const addAccountMasterApiHandler = async (params, token) => {
  setHeadersWithContentType2();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/account-ledger-master`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const accountMasterDeleteApiHandler = async (id, token) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/account-ledger-master?Id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const multipleDataAListApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(
      `${BASE_URL}/admin/company/account-ledger-master?id=${
        id ? id : 0
      }&mulipleData=true`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const bindDataAListApiHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/account-ledger-master?bindData=true`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getOpeningBalanceListApiHandler = async (token, all) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/opening-balance?all=${all ? 1 : 0}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const openingBalanceActiveInactiveApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/opening-balance`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const openingBalanceDeleteApiHandler = async (id, token) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/opening-balance?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const addOpeningBalanceApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/opening-balance`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const addOutstandingVoucherApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/out-standing-voucher`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const outstandingVoucherListApiHandler = async (token, all, id) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(
      `${BASE_URL}/admin/company/out-standing-voucher${
        id ? `/?id=${id}` : `?all=${!all ? 1 : 0}`
      }`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const unclearedChequeListApiHandler = async (token, all, id) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(
      `${BASE_URL}/admin/company/unclear-cheques${
        id ? `?id=${id}` : `?all=${!all ? 1 : 0}`
      }`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const addUnclearedChequeApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/unclear-cheques`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const resendVerificationEmailApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/resend-verification-email`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getSalesPurchaseListApiHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/sales-purchase-list`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const salesPrerequisiteApiHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/product-details-color`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const setMasterWithSizeApiHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-set-master-with-size`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getSalesUsersApiHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-sundray-debtors-sales`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const createSalesOrderApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/sales-order`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getSalesListApiHandler = async (
  token,
  all,
  id,
  selectedYearStart,
  selectedYearEnd
) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token, selectedYearStart, selectedYearEnd);
  return axios
    .get(
      `${BASE_URL}/admin/company/sales-order${
        id ? `?id=${id}` : `?all=${all ? 1 : 0}`
      }`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getProductSetRateAmount = async (token, productId, SetId) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(
      `${BASE_URL}/list/get-up-down-product-price?type=up&setId=${SetId}&productId=${productId}`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getProductSetRateAmount1 = async (
  token,
  productId,
  SetId,
  party
) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(
      `${BASE_URL}/list/get-up-down-product-price1?type=up&setId=${SetId}&productId=${productId}&party=${party}`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getSalesOrderNo = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-last-order-id`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const deleteSalesOrderApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/sales-order?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const addRoleApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/role-master`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getRoleListApiHandler = async (token, id, all) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(
      `${BASE_URL}/admin/company/role-master${
        id ? `?id=${id}` : `?all=${all ? 1 : 0}`
      }`
    )

    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const deleteRoleApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/role-master?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const updateRoleStatusApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/role-master`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const generateBarcodeApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/barcode-generator`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getProductSetWiseSizeMRPApiHandler = async (
  token,
  productId,
  SetId
) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(
      `${BASE_URL}/list/get-up-down-product-set-price??type=down&setId=${SetId}&productId=${productId}`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getBarcodeListApiHandler = async (token, all, id) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(
      `${BASE_URL}/admin/company/barcode-generator${
        id ? `?id=${id}` : `?all=${all ? 1 : 0}`
      }`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const deleteBarcodeApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/barcode-generator?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getFixedAssetCompanyApiHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/get-fix-assets-company`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getBarcodeChildListAPIHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/barcode-generator?type=barcodeChildList`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getSundryCreditorsAPIHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-sundray-creditors-finish-entry`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const generateFinishEntryApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/finish-entry`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getFinishEntryListApiHandler = async (
  token,
  id,
  all,
  selectedYearStart,
  selectedYearEnd
) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token, selectedYearStart, selectedYearEnd);
  return axios
    .get(
      `${BASE_URL}/admin/company/finish-entry${
        id ? `?id=${id}` : `?all=${all ? 1 : 0}`
      }`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const deleteFinishEntryApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/finish-entry?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getFinishEntryIdAPIHandler = async (token, type) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-last-finish-entry-id?type=${type}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getBarcodeAPIHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/generate-qr`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getExistenceBarcodeAPIHandler = async (
  token,
  productId,
  setId,
  colorId,
  type
) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(
      `${BASE_URL}/admin/company/barcode-generator?productId=${productId}&setId=${setId}&colorId=${colorId}&type=${type}`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getPartySalesOrderApiHandler = async (token, packingSlipId) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(
      `${BASE_URL}/admin/company/sales-order?type=partyOrder&packingSlipId=${packingSlipId}`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getBarcodeFullDataAPIHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/barcode-generator?type=fulldata`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getPackingSlipNoAPIHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-last-packing-slip-no`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getSalesRowPackingApiHandler = async (token, all, id) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-sales-row-packing?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const generatePackingSlipApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/packing-slip`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getPackingSlipListApiHandler = async (
  token,
  id,
  all,
  selectedYearStart,
  selectedYearEnd
) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token, selectedYearStart, selectedYearEnd);
  return axios
    .get(
      `${BASE_URL}/admin/company/packing-slip${
        id ? `?id=${id}` : `?all=${all ? 1 : 0}`
      }`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const deletePackingSlipApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/packing-slip?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getSalesEntryPrerequisiteDataApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-sales-entry-prerequisite-data`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getPackingRowSalesEnryApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-packing-row-sales-entry?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getSalesOrderPrintDataApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-sales-order-print-data?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getSalesPrintDataApiHandler = async (token, id, type) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(
      `${BASE_URL}/list/get-sales-voucher-print-data?voucherEntryId=${id}&type=${type}`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getDeliveryChallanPrintDataApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-delivery-challan-print-data?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getAllInvoiceNoApiHandler = async (token, type) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-all-invoice-no?type=${type}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const generateSalesEntryApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/sales-entry`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getSalesEntryListApiHandler = async (
  token,
  id,
  all,
  type = "salesEntry",
  selectedYearStart,
  selectedYearEnd
) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token, selectedYearStart, selectedYearEnd);
  return axios
    .get(
      `${BASE_URL}/admin/company/sales-entry${
        id ? `?id=${id}` : `?all=${all ? 1 : 0}&type=${type}`
      }`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const deleteSalesEntryApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/sales-entry?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getSalesOrderReportFilterOptionsApiHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(
      `${BASE_URL}/admin/company/get-sales-order-report-data?type=filterOptions`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getSalesOrderReportDataApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/get-sales-order-report-data`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

// Webview
export const getSalesOrderReportFilterOptionsWebviewApiHandler = async (
  companyId
) => {
  setHeadersWithContentType();
  return axios
    .get(
      `${BASE_URL}/admin/company/webview-get-sales-order-report-data?type=filterOptions&companyId=${companyId}`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getSalesOrderReportDataWebviewApiHandler = async (data) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/admin/company/webview-get-sales-order-report-data`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const setOffSalesOrderQtyApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/set-off-sales-order-qty?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getSalesReturnPrerequisiteDataApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-sales-return-prerequisite-data`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getAllNoteNoApiHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-all-note-no`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const generateSalesReturnApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/sales-return`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getSalesReturnListApiHandler = async (
  token,
  id,
  all,
  selectedYearStart,
  selectedYearEnd
) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token, selectedYearStart, selectedYearEnd);
  return axios
    .get(
      `${BASE_URL}/admin/company/sales-return${
        id ? `?id=${id}` : `?all=${all ? 1 : 0}`
      }`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const deleteSalesReturnApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/sales-return?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getInvoice1PrintDataApiHandler = async (token, id, type) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-invoice1-print-data?id=${id}&type=${type}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getPackingSlipReportFilterOptionsApiHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(
      `${BASE_URL}/admin/company/get-packing-slip-report-data?type=filterOptions`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getPackingSlipReportDataApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/get-packing-slip-report-data`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getBrokersApiHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-brokers`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getSalesEntryReportFilterOptionsApiHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(
      `${BASE_URL}/admin/company/get-sales-entry-report-data?type=filterOptions`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getSalesEntryReportDataApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/get-sales-entry-report-data`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getSalesReturnPrintDataApiHandler = async (token, id, type) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-sales-return-print-data?id=${id}&type=${type}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getSalesReturnReportFilterOptionsApiHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(
      `${BASE_URL}/admin/company/get-sales-return-report-data?type=filterOptions`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getSalesReturnReportDataApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/get-sales-return-report-data`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getStockReportFilterOptionsApiHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/get-stock-report-data?type=filterOptions`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getStockReportFilterOptionsWebviewApiHandler = async (
  companyId
) => {
  setHeadersWithContentType();
  // setGetHeadersWithAccessToken(token);
  return axios
    .get(
      `${BASE_URL}/admin/company/get-stock-report-data-webview?type=filterOptions&companyId=${companyId}`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getStockReportDataApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/get-stock-report-data`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getStockReportDataWebviewApiHandler = async (data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken();
  return axios
    .post(`${BASE_URL}/admin/company/get-stock-report-data-webview`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getSingleProductStockApiHandler = async (
  token,
  productId,
  colorId
) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(
      `${BASE_URL}/admin/company/get-single-product-stock-data?productId=${productId}&colorId=${colorId}`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getPurchaseOrderNo = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-last-purchase-order-id`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getPurchaseOrderPrerequisiteDataApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-purchase-order-prerequisite-data`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const generatePurchaseOrderApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/purchase-Order`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getPurchaseOrderListApiHandler = async (
  token,
  id,
  all,
  selectedYearStart,
  selectedYearEnd
) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token, selectedYearStart, selectedYearEnd);
  return axios
    .get(
      `${BASE_URL}/admin/company/purchase-order${
        id ? `?id=${id}` : `?all=${all ? 1 : 0}`
      }`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const deletePurchaseOrderApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/purchase-order?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getPurchaseOrderPrintDataApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-purchase-order-print-data?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getPurchaseOrderReportFilterOptionsApiHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(
      `${BASE_URL}/admin/company/get-purchase-order-report-data?type=filterOptions`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getPurchaseOrderReportDataApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/get-purchase-order-report-data`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const setOffPurchaseOrderQtyApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/set-off-purchase-order-qty?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getGRNNo = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-last-grn-id`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getGrnPrerequisiteDataApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-grn-prerequisite-data?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getPurchaseRowGRNApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-purchase-row-grn?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const generateGrnApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/grn`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getGrnListApiHandler = async (
  token,
  id,
  all,
  selectedYearStart,
  selectedYearEnd
) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token, selectedYearStart, selectedYearEnd);
  return axios
    .get(
      `${BASE_URL}/admin/company/grn${id ? `?id=${id}` : `?all=${all ? 1 : 0}`}`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const deleteGrnApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/grn?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getGrnPrintDataApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-grn-print-data?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getPurchasePrintDataApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-purchase-return-print-data?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getGrnReportFilterOptionsApiHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/get-grn-report-data?type=filterOptions`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getGrnReportDataApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/get-grn-report-data`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getPurchaseEntryPrerequisiteDataApiHandler = async (
  token,
  editId
) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-purchase-entry-prerequisite-data?id=${editId}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getGrnRowPurchaseEnryApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-grn-row-purchase-entry?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getPurchaseEntryNo = async (token, type) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-last-purchase-entry-id?type=${type}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const generatePurchaseEntryApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/purchase-entry`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getPurchaseEntryListApiHandler = async (
  token,
  id,
  all,
  type = "singleSize",
  selectedYearStart,
  selectedYearEnd
) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token, selectedYearStart, selectedYearEnd);
  return axios
    .get(
      `${BASE_URL}/admin/company/purchase-entry${
        id ? `?id=${id}&type=${type}` : `?all=${all}&type=${type}`
      }`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const deletePurchaseEntryApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/purchase-entry?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getPurchaseEntryReportDataApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/get-purchase-entry-report-data`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getPurchaseEntryReportFilterOptionsApiHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(
      `${BASE_URL}/admin/company/get-purchase-entry-report-data?type=filterOptions`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getPurchaseEntrySizeWisePrerequisiteDataApiHandler = async (
  token
) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-purchase-entry-size-wise-prerequisite-data`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const generatePurchaseEntrySizeWiseApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/purchase-entry-size-wise`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const deletePurchaseEntrySizeWiseApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/purchase-entry-size-wise?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getPurchaseReturnPrerequisiteDataApiHandler = async (
  token,
  editId
) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-purchase-return-prerequisite-data?id=${editId}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getPurchaseReturnNo = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-last-purchase-return-id`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const generatePurchaseReturnApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/purchase-return`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getPurchaseReturnListApiHandler = async (
  token,
  id,
  all,
  selectedYearStart,
  selectedYearEnd
) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token, selectedYearStart, selectedYearEnd);
  return axios
    .get(
      `${BASE_URL}/admin/company/purchase-return${
        id ? `?id=${id}` : `?all=${all}`
      }`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const deletePurchaseReturnApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/purchase-return?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getPurchaseReturnReportFilterOptionsApiHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(
      `${BASE_URL}/admin/company/get-purchase-return-report-data?type=filterOptions`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getPurchaseReturnReportDataApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/get-purchase-return-report-data`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getMaterialStockReportFilterOptionsApiHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(
      `${BASE_URL}/admin/company/get-material-stock-report-data?type=filterOptions`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getMaterialStockReportDataApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/get-material-stock-report-data`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const createUserApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/manage-users`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getCompanyUsersListApiHandler = async (token, id, all) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(
      `${BASE_URL}/admin/company/manage-users${
        id ? `?id=${id}` : `?all=${all}`
      }`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const approveRejectCompanyUserApiHandler = async (params, token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/manage-users`, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const companyUserDeleteApiHandler = async (id, token) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/manage-users/?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const updateCompanySetupApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/setup/`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getCompanySetupDataApiHandler = async (token, companyId) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/setup${`?companyId=${companyId}`}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const generateYearMasterApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/yearMaster`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getYearsListApiHandler = async (token, id, all) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(
      `${BASE_URL}/admin/company/yearMaster${
        id ? `?id=${id}` : `?all=${all ? 1 : 0}`
      }`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const yearMasterActiveDeactiveApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/yearMaster`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const yearMasterDeleteApiHandler = async (id, token) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/yearMaster/?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const setUserSelectedYearsApiHandler = async (token, year) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/setSelectedYear/${year}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getJournalVoucherPrerequisiteDataApiHandler = async (
  token,
  id
) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-journal-voucher-prerequisite-data`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const generateJournalVoucherApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/journal-voucher`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getAccountListApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/accountslist`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getJVListApiHandler = async (
  token,
  id,
  all,
  selectedYearStart,
  selectedYearEnd
) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token, selectedYearStart, selectedYearEnd);
  return axios
    .get(
      `${BASE_URL}/admin/company/journal-voucher${
        id ? `?id=${id}` : `?all=${all ? 1 : 0}`
      }`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const deleteJvApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/journal-voucher?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const generateCurrncyApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/currency`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getCurrencyListApiHandler = async (token, id, all) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(
      `${BASE_URL}/admin/company/currency${
        id ? `?id=${id}` : `?all=${all ? 1 : 0}`
      }`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getSalesVoucherPrerequisiteDataApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-sales-voucher-prerequisite-data`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getCreditNotePrerequisiteDataApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-credit-note-prerequisite-data`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getDebitNotePrerequisiteDataApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-debit-note-prerequisite-data`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getDebitNoteSalesPrerequisiteDataApiHandler = async (
  token,
  id
) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-debit-note-sales-prerequisite-data`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const currencyMasterDeleteApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/currency/?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getSalesVoucherEntryListApiHandler = async (
  token,
  id,
  all,
  type = "salesVoucher",
  selectedYearStart,
  selectedYearEnd
) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token, selectedYearStart, selectedYearEnd);
  return axios
    .get(
      `${BASE_URL}/admin/company/voucher-entry${
        id ? `?id=${id}` : `?all=${all ? 1 : 0}&type=${type}`
      }`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const generateSalesVoucherEntryApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/voucher-entry`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const deleteSalesVoucherEntryApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/voucher-entry?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getPurchaseVoucherPrerequisiteDataApiHandler = async (
  token,
  id
) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-purchase-voucher-prerequisite-data`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const uploadPdfApiHandler = async (data, token) => {
  setHeadersWithContentType2();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/uploadPdf`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getJVPrintDataApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-journal-voucher-print-data?transNo=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getSurplusWastagePrerequisiteDataApiHandler = async (token) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-suplus-wastage-prerequisite-data`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const generateSurplusWastageApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/surplus-wastage`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getSurplusWastageListApiHandler = async (
  token,
  id,
  all,
  selectedYearStart,
  selectedYearEnd
) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token, selectedYearStart, selectedYearEnd);
  return axios
    .get(
      `${BASE_URL}/admin/company/surplus-wastage${
        id ? `?id=${id}` : `?all=${all ? 1 : 0}`
      }`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const deleteSurplusWastageApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/surplus-wastage?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getStockTransferPrerequisiteDataApiHandler = async (token) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-stock-transfer-prerequisite-data`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const generateStockTransferApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/stock-transfer`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getStockTransferListApiHandler = async (
  token,
  id,
  all,
  selectedYearStart,
  selectedYearEnd
) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token, selectedYearStart, selectedYearEnd);
  return axios
    .get(
      `${BASE_URL}/admin/company/stock-transfer${
        id ? `?id=${id}` : `?all=${all ? 1 : 0}`
      }`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const deleteStockTransferApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/stock-transfer?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getCashBankPrerequisiteDataApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-cash-bank-prerequisite-data`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const generateCashBankApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/cash-bank`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getCashbankListApiHandler = async (
  token,
  id,
  all,
  selectedYearStart,
  selectedYearEnd
) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token, selectedYearStart, selectedYearEnd);
  return axios
    .get(
      `${BASE_URL}/admin/company/cash-bank${
        id ? `?id=${id}` : `?all=${all ? 1 : 0}`
      }`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const deleteCashbankApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/cash-bank?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getLrUpdationPrerequisiteDataApiHandler = async (token) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/lr-update`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getPartyListApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/lr-update`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getCashBankPrintDataApiHandler = async (token, id, type) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-cash-bank-print-data?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getAdvanceAdjustmentPrerequisiteDataApiHandler = async (token) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-advance-adjustment-prerequisite-data`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const createAdvanceAdjustmentApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/advance-adjustment`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const deleteAdjustEntryApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/advance-adjustment?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getTdsEntryPrerequisiteDataApiHandler = async (token, editId) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .get(`${BASE_URL}/list/get-tds-entry-prerequisite-data?editId=${editId}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const generateTdsEntryApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/tds-entry`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getTdsEntryListApiHandler = async (
  token,
  id,
  all,
  selectedYearStart,
  selectedYearEnd
) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token, selectedYearStart, selectedYearEnd);
  return axios
    .get(
      `${BASE_URL}/admin/company/tds-entry${
        id ? `?id=${id}` : `?all=${all ? 1 : 0}`
      }`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const deleteTdsEntryApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setHeadersWithDeleteAccessToken(token);
  return axios
    .delete(`${BASE_URL}/admin/company/tds-entry?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getTdsReportFilterOptionsApiHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/get-tds-report-data?type=filterOptions`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getTdsReportDataApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/get-tds-report-data`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getAccountReportFilterOptionsApiHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/get-account-report-filteroptions`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getAccountReportDataApiHandler = async (token, params) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/get-account-report`, { params })
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getProductMasterReportFilterOptionsApiHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/get-product-report-filteroptions`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getProductMasterReportDataApiHandler = async (token, params) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/get-product-report`, { params })
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getCashbankBookReportFilterOptionsApiHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(
      `${BASE_URL}/admin/company/get-cashbank-book-report-data?type=filterOptions`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getCashbankBookReportDataApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/get-cashbank-book-report-data`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getCashbankRegisterReportDataApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/get-cashbank-register-report-data`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const accountMasterCSVDataImportApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/account-master-csv`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getJVReportFilterOptionsApiHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/get-jv-report-data?type=filterOptions`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getJVReportDataApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/get-jv-report-data`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};
export const productMasterCSVDataImportApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/product-master-csv`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const generateSalesEntryInvoiceApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/generate-sales-entry-invoice`, { id })
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const generateEwayBillPDFApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/generate-EwayBill-PDF`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getLedgerReportFilterOptionsApiHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/get-ledger-report-data?type=filterOptions`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getLedgerReportDataApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/get-ledger-report-data`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const generatePurchaseReturnEwayBillApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/generate-purchase-return-invoice`, { id })
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const generateOtherSalesEntryEwayBillApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/generate-sales-other-ewaybill`, { id })
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getCompanyAddressApiHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(`${BASE_URL}/admin/company/GetCompanyAddress`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getCommissionReportFilterOptionsApiHandler = async (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
    .get(
      `${BASE_URL}/admin/company/get-commission-report-data?type=filterOptions`
    )
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getCommissionReportDataApiHandler = async (token, data) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/get-commission-report-data`, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const generateSalesEntryEInvoiceBillApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/generate-sales-entry-einvoicebill`, { id })
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const generateOtherSalesEntryEInvoiceBillApiHandler = async (
  token,
  id
) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/generate-other-sales-entry-einvoicebill`, {
      id,
    })
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const generateSalesReturnEInvoiceBillApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/generate-sales-return-einvoicebill`, {
      id,
    })
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const generateDebitNoteSalesEInvoiceApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/generate-debit-note-sales-einvoicebill`, {
      id,
    })
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const generateCreditNoteEInvoiceApiHandler = async (token, id) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
    .post(`${BASE_URL}/admin/company/generate-credit-note-einvoicebill`, { id })
    .then((e) => e.data)
    .catch((e) => handleError(e));
};
